import React, { ReactElement } from 'react'
import { Route } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

interface PrivateRouteProps {
  component: any
  computedMatch?: {
    path: string
    url: string
    isExact: boolean
    params: any
  }
  exact: boolean
  location?: {
    pathname: string
    search: string
    hash: string
    state: any
    key: string
  }
  path: string
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: PrivateRouteProps): ReactElement => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const Comp = withAuthenticationRequired(Component, {})
        return <Comp {...props} />
      }}
    />
  )
}

export default PrivateRoute

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__search--_uvyV {\n  display: flex;\n  height: 56px;\n  justify-content: space-between;\n}\n.style__sessionOverview--b8jJx {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 24px;\n  position: relative;\n}\n.style__table--f8SW6 {\n  box-shadow: none;\n  flex-grow: 1;\n  overflow: hidden;\n  padding: 16px 16px 16px 0;\n}\n.style__table--f8SW6 *::-webkit-scrollbar {\n  width: 0;\n  background: transparent;\n  /* make scrollbar transparent */\n}\n.style__tag--NsAbM {\n  background-color: #f5f5f5;\n  display: flex;\n  height: 72px;\n  margin: 16px 0;\n  padding: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/SessionOverview/style.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,YAAA;EACA,8BAAA;AAAJ;AAJA;EAQI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAXA;EAgBI,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,yBAAA;AAFJ;AAjBA;EAqBM,QAAA;EACA,uBAAA;EADJ,+BAA+B;AACjC;AAtBA;EA2BI,yBAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;AAFJ","sourcesContent":[":local {\n  .search {\n    display: flex;\n    height: 56px;\n    justify-content: space-between;\n  }\n\n  .sessionOverview {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    padding: 24px;\n    position: relative;\n  }\n\n  .table {\n    box-shadow: none;\n    flex-grow: 1;\n    overflow: hidden;\n    padding: 16px 16px 16px 0;\n    *::-webkit-scrollbar {\n      width: 0;\n      background: transparent; /* make scrollbar transparent */\n    }\n  }\n\n  .tag {\n    background-color: #f5f5f5;\n    display: flex;\n    height: 72px;\n    margin: 16px 0;\n    padding: 16px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "style__search--_uvyV",
	"sessionOverview": "style__sessionOverview--b8jJx",
	"table": "style__table--f8SW6",
	"tag": "style__tag--NsAbM"
};
export default ___CSS_LOADER_EXPORT___;

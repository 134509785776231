// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__menu--qkeDy {\n  display: flex;\n  flex-direction: column;\n  max-height: 100vh;\n}\n.style__pageContainer--n9swU {\n  flex-grow: 1;\n  height: calc(100vh - 97px);\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageContainer/style.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,iBAAA;AAAJ;AAJA;EAQI,YAAA;EACA,0BAAA;AADJ","sourcesContent":[":local {\n  .menu {\n    display: flex;\n    flex-direction: column;\n    max-height: 100vh;\n  }\n\n  .pageContainer {\n    flex-grow: 1;\n    height: calc(100vh - 97px);\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "style__menu--qkeDy",
	"pageContainer": "style__pageContainer--n9swU"
};
export default ___CSS_LOADER_EXPORT___;

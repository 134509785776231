import React, { useEffect, useRef, useState, ReactElement } from 'react'
import { Rnd } from 'react-rnd'
import { PlayIcon } from '../../assets'
import TimeLabel from '../TimeLabel'
import { Incident } from '../../../types'
import style from './style.less'

const KNOB_MARGIN = 6

interface PlaybackBarProperties {
  onJumpTo: (number) => void
  currentOffset: number
  duration: number
  incidentIndex: number
  incidents: Array<Incident>
  setPlaying: any
  playing: boolean
}

const PlaybackBar = ({
  duration,
  currentOffset = 120,
  incidents = [],
  onJumpTo,
  setPlaying,
  playing,
  incidentIndex,
}: PlaybackBarProperties): ReactElement => {
  const ref = useRef(null)
  const [offsetPosition, setOffsetPosition] = useState(
    (currentOffset / duration) * 100
  )
  const [knobPosition, setKnobPosition] = useState({ x: 0, y: 0 })
  const [offset, setOffset] = useState(currentOffset)

  const onLineClick = (event) => {
    const bounds = ref.current.getBoundingClientRect()
    const position = (event.pageX - bounds.x) / ref.current.offsetWidth
    setOffsetPosition(position * 100)
    setOffset(position * duration)
    setKnobPosition({
      x: ref.current.offsetWidth * position - KNOB_MARGIN,
      y: 0,
    })
    onJumpTo(position * duration)
  }

  const onDrag = (event) => {
    const bounds = ref.current.getBoundingClientRect()
    const position = (event.pageX - bounds.x) / ref.current.offsetWidth
    setOffsetPosition(position * 100)
    setOffset(position * duration)
  }

  const onDragStop = (event, d) => {
    const pos = (d.x + 6) / ref.current.offsetWidth
    setKnobPosition({
      x: d.x,
      y: 0,
    })
    onJumpTo(pos * duration)
  }

  useEffect(() => {
    setOffsetPosition((currentOffset / duration) * 100)
    setOffset(currentOffset)
    setKnobPosition({
      x: (ref.current.offsetWidth * currentOffset) / duration - KNOB_MARGIN,
      y: 0,
    })
  }, [currentOffset])

  useEffect(() => {
    window.addEventListener('resize', () => {
      ref.current &&
        setKnobPosition({
          x: (ref.current.offsetWidth * currentOffset) / duration - KNOB_MARGIN,
          y: 0,
        })
    })
  }, [ref?.current])

  return (
    <div className={style.playBackBar}>
      <div className={style.playBox}>
        <PlayIcon
          className={style.play}
          onClick={() => {
            setPlaying(!playing)
          }}
        />
      </div>
      <div className={style.timeLineBox}>
        <div className={style.time}>
          <TimeLabel seconds={offset} showMs={false} />
        </div>
        <div className={style.timeLine} onClick={onLineClick}>
          <div className={style.lineBox} ref={ref}>
            <div className={style.line} />
            <div
              className={style.backLine}
              style={{
                width: `${offsetPosition}%`,
              }}
            />
          </div>
          {incidents.map((incident, index) => (
            <div
              className={style.eventMarker}
              current={incidentIndex === index}
              key={incident.id}
              style={{
                background:
                  incidentIndex === index
                    ? '#0AD46C'
                    : incident.startOffset < offset
                    ? '#FFE140'
                    : '#9E9E9E',
                left: `${(incident.startOffset / duration) * 100}%`,
              }}
            />
          ))}
          <Rnd
            bounds="parent"
            className={style.knob}
            dragAxis="x"
            enableResizing={{
              bottom: false,
              bottomLeft: false,
              bottomRight: false,
              left: false,
              right: false,
              top: false,
              topLeft: false,
              topRight: false,
            }}
            onDrag={onDrag}
            onDragStop={onDragStop}
            position={knobPosition}
            size={{ height: 12, width: 12 }}
          />
        </div>
        <div className={style.time}>
          <TimeLabel seconds={duration} showMs={false} />
        </div>
      </div>
    </div>
  )
}

export default PlaybackBar

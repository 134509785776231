// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__formRow--SyKNs {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 12px;\n  position: relative;\n  width: 512px;\n}\n.style__icon--mgzOR {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  width: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Settings/components/Modal/style.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;EACA,YAAA;AAAJ;AANA;EAUI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;AADJ","sourcesContent":[":local {\n  .formRow {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 12px;\n    position: relative;\n    width: 512px;\n  }\n\n  .icon {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width: 24px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formRow": "style__formRow--SyKNs",
	"icon": "style__icon--mgzOR"
};
export default ___CSS_LOADER_EXPORT___;

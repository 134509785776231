import React, { ReactElement } from 'react'
import { useQuery, QueryHookOptions } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import { DocumentNode } from 'graphql'
import { VideoStream } from '../../../types/apollo'

type withQueryProps = {
  Component: React.FunctionComponent
  query: DocumentNode
  queryOptions?: QueryHookOptions
  useSkeleton?: boolean
}

const withQuery = ({
  Component,
  query,
  queryOptions,
  useSkeleton = true,
}: withQueryProps): ((withQueryProps) => ReactElement) => (
  props: withQueryProps
) => {
  const { data, loading } = useQuery(query, queryOptions)

  if (loading) {
    if (!useSkeleton) {
      return (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <CircularProgress />
        </div>
      )
    }
    return <Skeleton />
  }

  // HACK HERE
  if (queryOptions?.variables?.sessionId) {
    if (data.videoStreams) {
      const filteredData = data.videoStreams.data.filter(
        (videoStream: VideoStream) =>
          videoStream.sessionId === queryOptions.variables.sessionId
      )

      return (
        <Component
          data={{
            videoStreams: {
              data: filteredData,
            },
          }}
          {...props}
        />
      )
    }
  }

  return <Component data={data} {...props} />
}

export default withQuery

import React, { ReactElement } from 'react'

const Storm = (): ReactElement => (
  <div>
    <svg
      fill="none"
      height="22"
      viewBox="0 0 22 22"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.9526 5.21436C14.7157 2.09899 11.6889 0 8.26939 0C4.49918 0 1.20639 2.55163 0.263514 6.20384C-0.679357 9.85606 0.966484 13.6839 4.2651 15.5107C4.74806 15.7781 5.35629 15.6032 5.62361 15.12C5.89094 14.6368 5.71613 14.0283 5.23317 13.7608C2.73202 12.3757 1.48408 9.47328 2.199 6.70401C2.91393 3.93475 5.41066 2 8.26939 2C11.1281 2 13.6249 3.93475 14.3398 6.70401C14.4538 7.14549 14.8518 7.45393 15.3075 7.45393H16.4524L16.6398 7.45863C18.3776 7.5477 19.8061 8.89329 19.9827 10.6431C20.1657 12.4553 18.9463 14.1115 17.1622 14.4739C16.6212 14.5838 16.2717 15.1117 16.3815 15.6529C16.4914 16.1942 17.019 16.5438 17.5599 16.4339C20.3506 15.867 22.2578 13.2766 21.9716 10.4421L21.9438 10.2138C21.5561 7.49251 19.222 5.45114 16.4514 5.45393L16.043 5.45338L15.9526 5.21436ZM12.4636 9.25824C12.0043 8.95189 11.3838 9.07606 11.0776 9.53559L7.44302 14.9901L7.38595 15.0865C7.04628 15.7374 7.51437 16.5448 8.27466 16.5448L11.8581 16.5444L9.2603 20.4447L9.20123 20.5454C8.97354 20.9913 9.11102 21.547 9.53751 21.8314C9.99681 22.1378 10.6174 22.0136 10.9236 21.5541L14.5581 16.0995L14.6152 16.0032C14.9549 15.3522 14.4868 14.5448 13.7265 14.5448L10.1419 14.5444L12.7409 10.645L12.7999 10.5442C13.0276 10.0984 12.8901 9.54271 12.4636 9.25824Z"
        fill="black"
        fillOpacity="0.87"
        fillRule="evenodd"
      />
    </svg>
  </div>
)

export default Storm

import React, { useState, useEffect } from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useAuth0 } from '@auth0/auth0-react'
import VideoPlayer from '../VideoPlayer'
import { VideoStreams } from '../../../types'
import style from './style.less'

type MultiPlayerProps = {
  scrubbing: boolean
  startOffset: number
  streams: VideoStreams
  sessionId: string
  sessionDuration: number
  onProgress: (number) => void
  telemetries?: any
  playingPlaybackBar: boolean
  telemetryComponent: React.FunctionComponent
}

const supportedCameras = [
  {
    className: 'frontCameraContainer',
    positions: ['CAM_FRONT', 'CAM_FRONT_CENTER'],
    props: {},
  },
  {
    className: 'backCameraContainer',
    positions: ['CAM_BACK', 'CAM_REAR'],
  },
  {
    className: 'leftCameraContainer',
    positions: ['CAM_LEFT_BACKWARD', 'CAM_LEFT'],
    props: {},
  },
  {
    className: 'rightCameraContainer',
    positions: ['CAM_RIGHT_BACKWARD', 'CAM_RIGHT'],
    props: {},
  },
]

const MultiPlayer: React.FunctionComponent<MultiPlayerProps> = ({
  scrubbing,
  startOffset,
  onProgress,
  sessionId,
  streams,
  sessionDuration,
  playingBounds,
  telemetries,
  playingPlaybackBar,
  telemetryComponent: TelemetryComponent,
}) => {
  const [loading, setLoading] = useState(true)
  const [telemetryLoading, setTelemetryLoading] = useState(true)
  const [playing, setPlaying] = useState(false)
  const [cameras, setCameras] = useState([])
  const [buffering, setBuffering] = useState(false)
  const [currentPosition, setCurrentPositon] = useState(startOffset)
  const [authToken, setAuthToken] = useState()

  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      setAuthToken(token)
    }
    getToken()
  }, [])

  useEffect(() => {
    setPlaying(playingPlaybackBar)
  }, [playingPlaybackBar])

  const updateCurrentPosition = (offset) => {
    setCurrentPositon(offset)
    onProgress(offset)
  }

  useEffect(() => {
    const cameras = supportedCameras
      .map(({ positions, className, props }) =>
        Object.assign(
          { className, props },
          streams.find(({ cameraPosition }) =>
            positions.includes(cameraPosition)
          )
        )
      )
      .filter((camera) => camera.url)

    if (cameras.length) {
      cameras[0].props.onProgress = updateCurrentPosition
      setCameras(cameras)
    } else {
      setLoading(false)
    }
  }, [streams])

  const loadingArray = new Array(cameras.length).fill(true)
  const bufferingArray = new Array(cameras.length).fill(false)

  const handleLoading = (index, playerLoading) => {
    loadingArray[index] = playerLoading
    setLoading(loadingArray.includes(true))
  }

  const handleTelemetryLoading = (loading) => {
    setTelemetryLoading(loading)
  }

  useEffect(() => {
    scrubbing && setPlaying(false)
  }, [scrubbing])

  useEffect(() => {
    telemetries && setTelemetryLoading(false)
  }, [telemetries])

  const onBuffering = (index, isBuffering) => {
    bufferingArray[index] = isBuffering
    setBuffering(bufferingArray.includes(true))
  }

  return (
    <>
      <div className={style.multiPlayer}>
        {(loading || buffering || telemetryLoading) && !authToken && (
          <div className={style.loadingContainer}>
            <CircularProgress />
          </div>
        )}
        <div className={style.cameras}>
          <div className={style.rearCamera}>
            {cameras.map(
              ({ className, url, cameraPosition, props }, index) =>
                cameraPosition === 'CAM_REAR' && (
                  <div className={style[className]} key={cameraPosition}>
                    <VideoPlayer
                      buffering={buffering}
                      cameraPosition={cameraPosition}
                      loading={loading}
                      onLoading={(loading) => handleLoading(index, loading)}
                      playing={
                        !buffering && !loading && !telemetryLoading && playing
                      }
                      playingBounds={playingBounds}
                      scrubbing={scrubbing}
                      setBuffering={(isBuffering) =>
                        onBuffering(index, isBuffering)
                      }
                      setPlaying={setPlaying}
                      startOffset={startOffset}
                      token={authToken}
                      url={url}
                      {...props}
                    />
                  </div>
                )
            )}
          </div>
          {cameras.map(
            ({ className, url, cameraPosition, props }, index) =>
              cameraPosition === 'CAM_FRONT_CENTER' && (
                <div className={style[className]} key={cameraPosition}>
                  <div className={style.aspectRatio}>
                    <div className={style.telemetry}>
                      <TelemetryComponent
                        duration={sessionDuration}
                        offset={currentPosition}
                        onLoading={handleTelemetryLoading}
                        sessionId={sessionId}
                        telemetries={telemetries}
                      />
                    </div>
                  </div>
                  <VideoPlayer
                    buffering={buffering}
                    cameraPosition={cameraPosition}
                    loading={loading}
                    onLoading={(loading) => handleLoading(index, loading)}
                    playing={
                      !buffering && !loading && !telemetryLoading && playing
                    }
                    playingBounds={playingBounds}
                    scrubbing={scrubbing}
                    setBuffering={(isBuffering) =>
                      onBuffering(index, isBuffering)
                    }
                    setPlaying={setPlaying}
                    startOffset={startOffset}
                    token={authToken}
                    url={url}
                    {...props}
                  />
                </div>
              )
          )}
          <div className={style.bottomCameras}>
            {cameras.map(
              ({ className, url, cameraPosition, props }, index) =>
                cameraPosition !== 'CAM_REAR' &&
                cameraPosition !== 'CAM_FRONT_CENTER' && (
                  <div className={style[className]} key={cameraPosition}>
                    <VideoPlayer
                      buffering={buffering}
                      cameraPosition={cameraPosition}
                      currentPosition={currentPosition}
                      handleTelemetryLoading={handleTelemetryLoading}
                      loading={loading}
                      onLoading={(loading) => handleLoading(index, loading)}
                      playing={
                        !buffering && !loading && !telemetryLoading && playing
                      }
                      playingBounds={playingBounds}
                      scrubbing={scrubbing}
                      sessionDuration={sessionDuration}
                      sessionId={sessionId}
                      setBuffering={(isBuffering) =>
                        onBuffering(index, isBuffering)
                      }
                      setPlaying={setPlaying}
                      startOffset={startOffset}
                      telemetries={telemetries}
                      TelemetryComponent={TelemetryComponent}
                      token={authToken}
                      url={url}
                      {...props}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default MultiPlayer

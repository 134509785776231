import { Redirect, Route, Switch } from 'react-router-dom'
import React, { ReactElement } from 'react'
import PrivateRoute from './components/PrivateRoute'
import SessionOverview from './pages/SessionOverview'
import Settings from './pages/Settings'
import IncidentRoute from './routes/IncidentRoute'

const Routes = (): ReactElement => (
  <Switch>
    <PrivateRoute component={SessionOverview} exact path="/sessions/overview" />
    <PrivateRoute component={Settings} exact path="/sessions/settings" />
    <PrivateRoute
      component={IncidentRoute}
      exact
      path="/sessions/:sessionId/incidents/:incidentId?"
    />
    <Route
      component={({ location }) => {
        const params = new URLSearchParams(location.search)
        const path = params.get('location')
        return path ? (
          <Redirect to={path} />
        ) : (
          <Redirect to="/sessions/overview" />
        )
      }}
      path="/"
    />
  </Switch>
)

export default Routes

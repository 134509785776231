import TextField from '@material-ui/core/TextField'
import React, { ReactElement, useState } from 'react'
import { Incident } from '../../../types'
import OutcomeList from '../OutcomeList'
import { GET_INCIDENT } from '../../services/apollo/queries'
import withQuery from '../../services/apollo/withQuery'
import { useMessage } from '../../hooks'
import style from './style.less'

type IncidentPaneOnChangeProps = {
  comment: string | null
  isSafe: boolean | null
  triggerId: string | null
  outcomeIds: string[] | null
}

type IncidentPaneProps = {
  incidentId: string
  data: {
    incident: Incident
  }
  onChange?: (props: IncidentPaneOnChangeProps) => void
}

// @TODO Defaults should probably be in graphql
const mapIncidentToDefaults = (incident: Incident) => ({
  comment: incident.comment || '',
  isSafe: incident.isSafe,
  outcomeIds: incident.outcomeIds || [],
  triggerId: incident.triggerId,
})

const IncidentPane: React.FunctionComponent<IncidentPaneProps> = ({
  data,
  onChange,
}) => {
  const incident = mapIncidentToDefaults(data.incident)
  const [comment, setComment] = useState(incident.comment)
  const [outcomeIds, setOutcomeIds] = useState(incident.outcomeIds)
  const message = useMessage()

  const triggerChange = (payload) => {
    if (onChange) {
      onChange({
        comment,
        isSafe: !(outcomeIds.length || incident.triggerId),
        outcomeIds,
        triggerId: incident.triggerId,
        ...payload,
      })
    }
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault()
    setComment(e.target.value)
    triggerChange({ comment: e.target.value })
  }

  const handleOutcomesChange = (outcomeIds: string[]) => {
    setOutcomeIds(outcomeIds)
    triggerChange({ outcomeIds })
  }

  return (
    <div className={style.incidentPane}>
      <>
        <h6 className={style.header}>
          {message('components.IncidentPane.outcomeTitle')}
        </h6>
        <OutcomeList onChange={handleOutcomesChange} outcomeIds={outcomeIds} />
      </>

      {outcomeIds.length > 0 && (
        <>
          <h6 className={style.header}>
            {message('components.IncidentPane.commentTitle')}
          </h6>
          <TextField
            InputLabelProps={{ shrink: true }}
            label={message('components.IncidentPane.commentLabel')}
            multiline
            onChange={handleCommentChange}
            placeholder={message('components.IncidentPane.commentPlaceholder')}
            rows={1}
            rowsMax={8}
            style={{ width: '100%' }}
            value={comment}
            variant="outlined"
          />
        </>
      )}
    </div>
  )
}

const IncidentPaneWithQueryOptions = ({
  incidentId,
  ...props
}: IncidentPaneProps): ReactElement =>
  withQuery({
    Component: IncidentPane,
    query: GET_INCIDENT,
    queryOptions: {
      fetchPolicy: 'network-only',
      variables: {
        id: incidentId,
      },
    },
    useSkeleton: false,
  })(props)

export default IncidentPaneWithQueryOptions

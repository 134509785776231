import classNames from 'classnames'
import React, { ReactElement } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { YaakLogo } from '../../assets'
import LanguageSwitch from '../LanguageSwitch'

const useStyles = makeStyles(() =>
  createStyles({
    toolbar: {
      'align-items': 'center',
      display: 'flex',
    },
  })
)

const Menu = (): ReactElement => {
  const classes = useStyles()

  return (
    <AppBar
      color="inherit"
      position="sticky"
      style={{ boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)', height: 96 }}
    >
      <Toolbar className={classNames(classes.toolbar)}>
        <Typography>
          <YaakLogo style={{ height: 42, margin: '27px 0px', width: 92 }} />
        </Typography>
      </Toolbar>
      <LanguageSwitch />
    </AppBar>
  )
}

export default Menu

import BasisGrotesqueBold from './BasisGrotesque-Bold-Pro.woff2'
import BasisGrotesqueBoldItalic from './BasisGrotesque-BoldItalic-Pro.woff2'
import BasisGrotesqueLight from './BasisGrotesque-Light-Pro.woff2'
import BasisGrotesqueLightItalic from './BasisGrotesque-LightItalic-Pro.woff2'
import BasisGrotesqueMedium from './BasisGrotesque-Medium-Pro.woff2'
import BasisGrotesqueMediumItalic from './BasisGrotesque-MediumItalic-Pro.woff2'
import BasisGrotesqueRegular from './BasisGrotesque-Regular-Pro.woff2'
import BasisGrotesqueRegularItalic from './BasisGrotesque-Italic-Pro.woff2'

const baseFontConfig: CSS.FontFace = {
  fontDisplay: 'swap',
  fontFamily: 'Basis Grotesque Pro',
  fontStyle: 'normal',
  fontWeight: 400,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

// BOLD
const basisGrotesqueBold = {
  ...baseFontConfig,
  fontWeight: 700,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueBold}) format('woff2')
  `,
}

const basisGrotesqueBoldItalic = {
  ...baseFontConfig,
  fontStyle: 'italic',
  fontWeight: 700,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueBoldItalic}) format('woff2')
  `,
}

// LIGHT
const basisGrotesqueLight = {
  ...baseFontConfig,
  fontWeight: 300,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueLight}) format('woff2')
  `,
}

const basisGrotesqueLightItalic = {
  ...baseFontConfig,
  fontStyle: 'italic',
  fontWeight: 300,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueLightItalic}) format('woff2')
  `,
}

// MEDIUM
const basisGrotesqueMedium = {
  ...baseFontConfig,
  fontWeight: 300,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueMedium}) format('woff2')
  `,
}

const basisGrotesqueMediumItalic = {
  ...baseFontConfig,
  fontStyle: 'italic',
  fontWeight: 300,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueMediumItalic}) format('woff2')
  `,
}

// REGULAR
const basisGrotesqueRegular = {
  ...baseFontConfig,
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueRegular}) format('woff2')
  `,
}

const basisGrotesqueRegularItalic = {
  ...baseFontConfig,
  fontStyle: 'italic',
  src: `
    local('Basis Grotesque'),
    url(${BasisGrotesqueRegularItalic}) format('woff2')
  `,
}

const fonts: CSS.FontFace[] = [
  basisGrotesqueBold,
  basisGrotesqueBoldItalic,
  basisGrotesqueLight,
  basisGrotesqueLightItalic,
  basisGrotesqueMedium,
  basisGrotesqueMediumItalic,
  basisGrotesqueRegular,
  basisGrotesqueRegularItalic,
]

export default fonts

import { Paper, withStyles } from '@material-ui/core'
import { format } from 'date-fns'
import React, { useState, useEffect } from 'react'
import Divider from '@material-ui/core/Divider'
import { useLazyQuery } from '@apollo/client/'
import PageContainer from '../../components/PageContainer'
import Table from '../../components/Table'
import {
  GET_SESSIONS_V2,
  GET_INCIDENTS,
  GET_INCIDENT_OUTCOMES,
} from '../../services/apollo/queries'
import { useMessage } from '../../hooks'
import { Incidents, Sessions } from '../../../types'
import SearchField from '../../components/SearchField/SearchField'
import Calendar from '../../components/Calendar'
import Tag from '../../components/Tag'
import GA_EVENT_ACTIONS from '../../tracking/consts/eventActions'
import GA_EVENT_CATEGORIES from '../../tracking/consts/eventCategories'
import trackEvent from '../../tracking/util/trackEvent'
import style from './style.less'

const LIMIT = 15

const styles = () => ({
  root: {
    '@global': {
      '*::-webkit-scrollbar': {
        background: 'transparent',
        width: '0px',
      },
    },
    boxShadow: 'none',
    flexGrow: 1,
    overflow: 'hidden',
    padding: '16px 16px 16px 0px',
  },
})

type SessionOverviewProps = {
  data: {
    incidents: Incidents
    sessions: Sessions
  }
}

const driveTypes = {
  1: 'Expert',
  2: 'Student',
  3: 'Test',
}

const sessionStatus = {
  0: 'pages.SessionOverview.sessionStatus.uploading',
  1: 'pages.SessionOverview.sessionStatus.uploaded',
  2: 'pages.SessionOverview.sessionStatus.processing',
  3: 'pages.SessionOverview.sessionStatus.processed',
  4: 'pages.SessionOverview.sessionStatus.processingFailed',
}

export const SessionOverview: React.FunctionComponent<SessionOverviewProps> = ({
  data,
  classes,
}) => {
  const [selectedTags, setSelectedTags] = useState([])
  const [selectedRange, setSelectedRange] = useState()
  const [
    getSessions,
    {
      data: sessionsV2,
      fetchMore: fetchMoreSessions,
      networkStatus: sessionsStatus,
    },
  ] = useLazyQuery(GET_SESSIONS_V2, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [
    getIncidents,
    {
      data: incidents,
      fetchMore: fetchMoreIncidents,
      networkStatus: incidentsStatus,
    },
  ] = useLazyQuery(GET_INCIDENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [getIncidentOutcomes, { data: outcomeData }] = useLazyQuery(
    GET_INCIDENT_OUTCOMES,
    {
      fetchPolicy: 'cache-and-network',
    }
  )
  const [mode, setMode] = useState('drives')
  const [variables, setVariables] = useState({})
  const message = useMessage()

  useEffect(() => {
    getIncidentOutcomes()
  }, [getIncidentOutcomes])

  useEffect(() => {
    const storedQuery = window.localStorage.getItem('lastQuery')
    const query = storedQuery ? JSON.parse(storedQuery) : {}
    const mode = window.localStorage.getItem('mode') || 'drives'

    query.range = Object.keys(query.range || {}).length
      ? query.range
      : undefined

    setVariables(query)
    setMode(mode)
    setSelectedRange(query.range)
    search(query, mode)
  }, [])

  useEffect(() => {
    if (outcomeData && variables.tags) {
      setSelectedTags(
        outcomeData.incidentOutcomes.data.filter((outcome) =>
          variables.tags.includes(outcome.id)
        )
      )
    }
  }, [outcomeData])

  const tabTitles = {
    completed: 'Completed',
    upcoming: 'Upcoming',
  }
  const headers = [
    // message('pages.SessionOverview.tableHeaders.log'),
    ...(mode !== 'drives'
      ? [message('pages.SessionOverview.tableHeaders.event')]
      : []),
    message('pages.SessionOverview.tableHeaders.driveId'),
    message('pages.SessionOverview.tableHeaders.driverId'),
    message('pages.SessionOverview.tableHeaders.partner'),
    message('pages.SessionOverview.tableHeaders.driveType'),
    message('pages.SessionOverview.tableHeaders.startTimestamp'),
    message('pages.SessionOverview.tableHeaders.time'),
    message('pages.SessionOverview.tableHeaders.area'),
    message('pages.SessionOverview.tableHeaders.startLocation'),
    message('pages.SessionOverview.tableHeaders.vehicle'),
    ...(mode === 'drives'
      ? [
          message('pages.SessionOverview.tableHeaders.metadataStatus'),
          message('pages.SessionOverview.tableHeaders.videoStatus'),
          message('pages.SessionOverview.tableHeaders.fullVideoStatus'),
          message('pages.SessionOverview.tableHeaders.events'),
        ]
      : []),
  ]

  const options = outcomeData?.incidentOutcomes?.data.map((option) => {
    return {
      tag: true,
      ...option,
    }
  })

  const mapSessionsToRows = (data) => {
    return data.sessionsV2.data.map((session) => {
      return [
        session?.canonicalName || '-',
        session.driver
          ? `${session.driver.firstname} ${session.driver.lastname}`
          : '-',
        session.partner?.name || '-',
        driveTypes[session.driveType] || '-',
        format(new Date(session.startTimestamp), 'dd MMM yyyy'),
        format(new Date(session.startTimestamp), 'HH:mm'),
        session.location && session.location.area ? session.location.area : '-',
        session.location && session.location.street && session.location.address
          ? `${session.location.street} ${session.location.address}`
          : '-',
        session?.dongleId || '-',
        session.metadataStatus ? message(sessionStatus[session.metadataStatus]) : '-',
        session.videoStatus ? message(sessionStatus[session.videoStatus]) : '-',
        session.fullVideoStatus ? message(sessionStatus[session.fullVideoStatus]) : '-',
        `${session.incidentCount || 0}`,
        session,
      ]
    })
  }

  const mapIncidentsToRows = (data) => {
    return data.incidents.data.map((incident) => {
      const tags = JSON.stringify(
        incident?.outcomeIds?.map(
          (tag) => options.find((el) => el.id === tag).label
        )
      )
      return [
        tags?.substring(1, tags.length - 1).replace(/["']/g, '') || '-',
        incident.sessionName,
        incident.driver
          ? `${incident.driver.firstname} ${incident.driver.lastname}`
          : '-',
        incident.partner?.name || '-',
        driveTypes[incident.driveType] || '-',
        format(new Date(incident.startTimestamp), 'dd MMM yyyy'),
        format(new Date(incident.startTimestamp), 'HH:mm'),
        incident.location ? incident.location.area : '-',
        incident.location
          ? `${incident.location.street} ${incident.location.address}`
          : '-',
        incident.dongleId || '-',
        incident,
      ]
    })
  }

  const search = (variables, mode) => {
    window.localStorage.setItem('lastQuery', JSON.stringify(variables))
    setVariables(variables)
    const query = buildQuery(variables)
    trackEvent({
      action: GA_EVENT_ACTIONS.SESSION_OVERVIEW.SEARCH,
      category: GA_EVENT_CATEGORIES.OVERVIEW,
      label: JSON.stringify(variables),
    })
    mode === 'drives'
      ? getSessions({ variables: query })
      : getIncidents({ variables: query })
  }

  const buildQuery = () => {
    const query = JSON.parse(JSON.stringify(variables))
    if (query.range) {
      query.range.start = variables.range.start || new Date(0)
      query.range.end = variables.range.end || new Date()
    }
    if (!query.limit) {
      query.limit = LIMIT
    }
    return query
  }

  const handleModeChange = (mode) => {
    setMode(mode)
    window.localStorage.setItem('mode', mode)
    search(variables, mode)
  }

  const handleTagChange = (tag) => {
    let tags = []
    if (tag && tag.id) {
      if (!selectedTags.find((t) => t.id === tag.id)) {
        tags = [tag].concat(selectedTags)
        setSelectedTags(tags)
        search(Object.assign(variables, { tags: tags.map((t) => t.id) }), mode)
      }
    }
  }

  const handleTagRemove = (tag) => {
    const newTags = selectedTags.filter((t) => t.id !== tag.id)
    setSelectedTags(newTags)
    search(
      Object.assign(variables, {
        tags: newTags.length ? newTags.map((t) => t.id) : undefined,
      }),
      mode
    )
  }

  const handleDateChange = ({ start, end }) => {
    setSelectedRange({ end, start })
    trackEvent({
      action: GA_EVENT_ACTIONS.SESSION_OVERVIEW.DATE_CHANGE,
      category: GA_EVENT_CATEGORIES.OVERVIEW,
      label: JSON.stringify({ end, start }),
    })
    search(Object.assign(variables, { range: { end, start } }), mode)
  }

  const moreSessions = (lastIndex) => {
    if (sessionsV2 && lastIndex < sessionsV2.sessionsV2.metaData.totalCount) {
      fetchMoreSessions({
        updateQuery: (pre, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return pre
          }
          return {
            sessionsV2: {
              __typename: 'SessionsV2',
              data: [...pre.sessionsV2.data, ...fetchMoreResult.sessionsV2.data],
              metaData: {
                __typename: 'MetaData',
                count:
                  pre.sessionsV2.data.length +
                  fetchMoreResult.sessionsV2.metaData.count,
                totalCount: fetchMoreResult.sessionsV2.metaData.totalCount,
              },
            },
          }
        },
        variables: { skip: lastIndex, ...buildQuery() },
      })
    }
  }

  const moreIncidents = (lastIndex) => {
    if (incidents && lastIndex < incidents.incidents.metaData.totalCount) {
      fetchMoreIncidents({
        updateQuery: (pre, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return pre
          }
          return {
            incidents: {
              __typename: 'Incidents',
              data: [...pre.incidents.data, ...fetchMoreResult.incidents.data],
              metaData: {
                __typename: 'MetaData',
                count:
                  pre.incidents.data.length +
                  fetchMoreResult.incidents.metaData.count,
                totalCount: fetchMoreResult.incidents.metaData.totalCount,
              },
            },
          }
        },
        variables: { limit: LIMIT, skip: lastIndex, ...buildQuery() },
      })
    }
  }

  return (
    <PageContainer>
      <div className={style.sessionOverview}>
        <div className={style.search}>
          <SearchField
            mode={mode}
            onChange={handleTagChange}
            onModeChange={handleModeChange}
            options={options}
            selectedTags={selectedTags}
          />
          <Calendar onChange={handleDateChange} range={selectedRange} />
        </div>
        {selectedTags.length > 0 && (
          <div className={style.tag}>
            {selectedTags.map((t, i) => (
              <Tag
                key={i}
                label={t.label}
                onDelete={() => handleTagRemove(t)}
                selected={true}
              />
            ))}
          </div>
        )}
        <Divider component="br" />
        <Paper className={classes.root} square>
          <Table
            fetchMore={mode === 'drives' ? moreSessions : moreIncidents}
            headers={headers}
            loading={
              mode === 'drives'
                ? sessionsStatus === 3 || sessionsStatus === 1
                : incidentsStatus === 3 || incidentsStatus === 1
            }
            rows={
              mode === 'drives'
                ? mapSessionsToRows(sessionsV2 || { sessionsV2: { data: [] } })
                : mapIncidentsToRows(incidents || { incidents: { data: [] } })
            }
            title={tabTitles.upcoming}
          />
        </Paper>
      </div>
    </PageContainer>
  )
}

export default withStyles(styles)(SessionOverview)

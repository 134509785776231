import { Chip, withStyles } from '@material-ui/core'
import React from 'react'
import { ChipProps } from '@material-ui/core/Chip'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'

type TagProps = {
  selected: boolean
}

const styles = () => ({
  root: {
    '&:first-child': {
      marginLeft: '0',
    },
    '&:not([class*="MuiChip-outlined"])': {
      backgroundColor: '#00EE77',
      color: '#000000',
    },
    borderRadius: '20px',
    height: '40px',
    marginBottom: '16px',
    marginRight: '16px',
  },
})

const Tag: React.FunctionComponent<TagProps & ChipProps> = ({
  classes,
  selected,
  ...props
}) => {
  return (
    <Chip
      className={classes.root}
      clickable={true}
      deleteIcon={<ClearOutlinedIcon style={{ color: '#000000' }} />}
      variant={selected ? 'default' : 'outlined'}
      {...props}
    />
  )
}

export default withStyles(styles)(Tag)

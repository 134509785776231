// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__addNewEvent--L8TMT {\n  padding: 0 64px;\n}\n.style__addNewEventDescription--EP_Qk {\n  color: rgba(255, 255, 255, 0.6);\n  font-size: 16px;\n  font-weight: 400;\n  letter-spacing: 0.5px;\n}\n.style__addNewEventTitle--rKj4w {\n  color: rgba(255, 255, 255, 0.87);\n  font-size: 24px;\n  font-weight: 700;\n  letter-spacing: 0.18px;\n}\n.style__container--b3NYU {\n  background: inherit;\n  margin: auto;\n  max-width: 100%;\n}\n.style__buttonGroup--wKFvD {\n  margin-top: 46px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/IncidentCreate/style.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;AAAJ;AAFA;EAMI,+BAAA;EACA,eAAA;EACA,gBAAA;EACA,qBAAA;AADJ;AARA;EAaI,gCAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;AAFJ;AAdA;EAoBI,mBAAA;EACA,YAAA;EACA,eAAA;AAHJ;AAnBA;EA0BI,gBAAA;AAJJ","sourcesContent":[":local {\n  .addNewEvent {\n    padding: 0 64px;\n  }\n\n  .addNewEventDescription {\n    color: rgba(255, 255, 255, 0.6);\n    font-size: 16px;\n    font-weight: 400;\n    letter-spacing: 0.5px;\n  }\n\n  .addNewEventTitle {\n    color: rgba(255, 255, 255, 0.87);\n    font-size: 24px;\n    font-weight: 700;\n    letter-spacing: 0.18px;\n  }\n\n  .container {\n    background: inherit;\n    margin: auto;\n    max-width: 100%;\n  }\n\n  .buttonGroup {\n    margin-top: 46px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addNewEvent": "style__addNewEvent--L8TMT",
	"addNewEventDescription": "style__addNewEventDescription--EP_Qk",
	"addNewEventTitle": "style__addNewEventTitle--rKj4w",
	"container": "style__container--b3NYU",
	"buttonGroup": "style__buttonGroup--wKFvD"
};
export default ___CSS_LOADER_EXPORT___;

import React, {
  useEffect,
  useState,
  createContext,
  ReactElement,
  ReactChild,
  ReactChildren,
} from 'react'
import { useCookies } from 'react-cookie'
import config from '../config'

export const LanguageContext = createContext({})

interface LanguageProps {
  children: ReactChild | ReactChildren
}

export const LanguageProvider = ({ children }: LanguageProps): ReactElement => {
  const [language, setLanguage] = useState(config.locales.default)
  const [cookies, setCookie] = useCookies()

  const updateLanguage = (lang) => {
    setCookie('language', lang)
    setLanguage(lang)
  }

  useEffect(() => {
    const lang = cookies.language
    if (lang) {
      setLanguage(lang)
    }
  }, [cookies])

  const lanugageContext = {
    language,
    updateLanguage,
  }

  return (
    <LanguageContext.Provider value={lanugageContext}>
      {children}
    </LanguageContext.Provider>
  )
}

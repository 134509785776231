import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import pinStart from '../../assets/images/pin-start.svg'
import pinEnd from '../../assets/images/pin-end.svg'

import MapNavigation from '../MapNavigation'
import style from './style.less'

mapboxgl.accessToken =
  'pk.eyJ1IjoibWFydGluLXlhYWsiLCJhIjoiY2tmOGJ2NmllMGF0NTJzbGM5aW8xNm5mYiJ9.IgJV_d5hwQJQE0PXUXm3_A'

const useStyles = makeStyles(() => ({
  map: {
    height: '100%',
    overflow: 'hidden',
  },
}))

const MAX_ZOOM_LEVEL = 22

interface FeaturesProps {
  geometry: {
    type: string
    coordinates: number[]
  }
  properties: {
    incidentId: string
  }
  type: string
}

interface geoFeatureCollectionProps {
  features: FeaturesProps[]
  type: string
}

interface TelemetryProps {
  acceleration: number
  brakePressed: boolean
  gasPedal: number
  gear: number
  speed: number
  steeringWheelAngle: number
  turnSignals: string
}

interface MapProps {
  telemetries: TelemetryProps[]
  currentIncidentId: string
  geoFeatureCollection: geoFeatureCollectionProps
  onIncidentSelected: (string) => void
}

const Map = ({
  telemetries,
  currentIncidentId,
  geoFeatureCollection,
  onIncidentSelected,
}: MapProps): ReactElement => {
  const [map, setMap] = useState(null)
  const mapContainer = useRef(null)
  const classes = useStyles()

  const selectIncident = (e) => {
    // onIncidentSelected(e.features[0].properties.incidentId)
    // filterCurriculumItems(e.features[0].properties.curriculum_items, map)
    filterCurriculumItems(
      JSON.parse(e.features[0].properties.curriculum_items),
      e.features[0].properties.id,
      map
    )
  }

  useEffect(() => {
    const initializeMap = ({ setMap, mapContainer }) => {
      const map = new mapboxgl.Map({
        // stylesheet location
        center: [0, 0],

        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v9',
        zoom: 5,
      })

      map.on('load', () => {
        setMap(map)
        map.resize()

        initMapComponents(
          map,
          geoFeatureCollection,
          currentIncidentId,
          selectIncident
        )
      })
    }

    if (!map) return initializeMap({ mapContainer, setMap })
  }, [map, currentIncidentId, geoFeatureCollection])

  useEffect(() => {
    if (map) {
      addGeoSource(
        map,
        'currentIncidentMarker',
        geoFeatureCollection.features
          .filter(
            (feature) =>
              feature.geometry.type === 'Point' &&
              feature.properties.type === 'ANNOTATION'
          )
          .filter(
            ({ properties }) => properties.incidentId === currentIncidentId
          )
      )
    }
  }, [map, currentIncidentId, geoFeatureCollection])

  useEffect(() => {
    if (map) {
      const curIncidentRoute = {
        coordinates: telemetries.map(({ longitude, latitude }) => [
          longitude,
          latitude,
        ]),
        type: 'LineString',
      }
      addGeoSource(map, 'currentIncident', [
        { geometry: curIncidentRoute, type: 'Feature' },
      ])
    }
  }, [map, telemetries])

  const onIncreaseZoom = () => {
    const currentZoom = map.getZoom()
    currentZoom + 1 < MAX_ZOOM_LEVEL && map.setZoom(currentZoom + 1)
  }

  const onDecreaseZoom = () => {
    const currentZoom = map.getZoom()
    currentZoom - 1 > 0 && map.setZoom(currentZoom - 1)
  }

  const onCenter = () => {
    const currentMarker = geoFeatureCollection.features.find(
      ({ properties }) => properties?.incidentId === currentIncidentId
    )
    if (currentMarker) {
      map.setCenter(currentMarker.geometry.coordinates)
    }
  }

  useEffect(() => {
    if (map) {
      map.on('click', 'incidentMarker', selectIncident)
      map.on('click', (e) => {
        const features = map.queryRenderedFeatures(e.point)
        if (
          features.every((feature) => feature.layer.id !== 'incidentMarker')
        ) {
          removeFilter(map)
        }
      })
    }
    return () => map && map.off('click', 'incidentMarker', selectIncident)
  })

  return (
    <>
      <div className={classes.map} ref={(el) => (mapContainer.current = el)} />
      <div className={style.navigation}>
        <MapNavigation
          onCenter={onCenter}
          onDecreaseZoom={onDecreaseZoom}
          onIncreaseZoom={onIncreaseZoom}
        />
      </div>
      <div className={style.legend}>
        <div className={style.legendContent}>
          {geoFeatureCollection.features
            .filter((feat) => feat.properties.type === 'CURRICULUM')
            .map((feat) => ({
              type: feat.properties.curriculum_type || feat.properties.type,
              stroke: feat.properties.stroke || '#006666',
            }))
            .reduce(
              (unique, item) =>
                unique.some(
                  (i) => i.type === item.type && i.stroke === item.stroke
                )
                  ? unique
                  : [...unique, item],
              []
            )
            .map((f) => (
              <div
                className={style.legendItem}
                style={{ backgroundColor: f.stroke }}
              >
                {f.type}
              </div>
            ))}
        </div>
      </div>
    </>
  )
}

const initMapComponents = (
  map,
  geoFeatureCollection,
  currentIncidentId,
  jumpToIncident
) => {
  addRoute(
    map,
    geoFeatureCollection.features.find(
      (feature) =>
        feature.geometry.type === 'LineString' ||
        feature.geometry.type === 'MultiLineString'
    )
  )
  addIncidents(
    map,
    geoFeatureCollection.features.filter(
      (feature) =>
        feature.geometry.type === 'Point' &&
        feature.properties.type === 'INCIDENT'
    ),
    currentIncidentId,
    jumpToIncident
  )

  addBookmarks(
    map,
    geoFeatureCollection.features.filter(
      (feature) =>
        feature.geometry.type === 'Point' &&
        feature.properties.type === 'BOOKMARK'
    )
  )

  addCurriculum(
    map,
    geoFeatureCollection.features.filter(
      (feature) => feature.properties.type === 'CURRICULUM'
    )
  )
}

const addRoute = (map, route) => {
  addGeoSource(map, 'route', [route])
  addGeoSource(map, 'start', [
    {
      geometry: {
        coordinates: route.geometry.coordinates[0],
        type: 'Point',
      },
      type: 'Feature',
    },
  ])
  addGeoSource(map, 'end', [
    {
      geometry: {
        coordinates:
          route.geometry.coordinates[route.geometry.coordinates.length - 1],
        type: 'Point',
      },
      type: 'Feature',
    },
  ])

  const start = new Image(26, 26)
  start.onload = () => map.addImage('pinStart', start)
  start.src = pinStart

  const end = new Image(26, 26)
  end.onload = () => map.addImage('pinEnd', end)
  end.src = pinEnd

  addLayer(map, {
    id: 'route',
    layout: { 'line-cap': 'round' },
    paint: {
      'line-color': '#006666',
      'line-opacity': 0.75,
      'line-width': 5,
    },
    source: 'route',
    type: 'line',
  })

  addLayer(map, {
    id: 'start',
    layout: { 'icon-image': 'pinStart' },
    source: 'start',
    type: 'symbol',
  })
  addLayer(map, {
    id: 'end',
    layout: { 'icon-image': 'pinEnd' },
    source: 'end',
    type: 'symbol',
  })

  if (route.geometry.coordinates.length) zoomToGeoSource(map, route.geometry)
}

const addIncidents = (map, points, currentIncident, jumpToIncident) => {
  addGeoSource(map, 'incidentMarker', points)

  addGeoSource(
    map,
    'currentIncidentMarker',
    points.filter(({ properties }) => properties.incidentId === currentIncident)
  )

  addGeoSource(map, 'currentIncident', [])

  addLayer(map, {
    id: 'incidentMarker',
    paint: {
      'circle-color': '#FFFFFF',
      'circle-pitch-scale': 'map',
      'circle-radius': 5,
      'circle-stroke-color': '#006666',
      'circle-stroke-width': 7,
    },
    source: 'incidentMarker',
    type: 'circle',
  })
  addLayer(map, {
    id: 'currentIncident',
    paint: {
      'line-color': '#00EE77',
      'line-opacity': 0.75,
      'line-width': 5,
    },
    source: 'currentIncident',
    type: 'line',
  })
  addLayer(map, {
    id: 'currentIncidentMarker',
    paint: {
      'circle-color': '#FFFFFF',
      'circle-pitch-scale': 'map',
      'circle-radius': 5,
      'circle-stroke-color': '#00EE77',
      'circle-stroke-width': 7,
    },
    source: 'currentIncidentMarker',
    type: 'circle',
  })

  addLayer(map, {
    id: 'selectedMarker',
    paint: {
      'circle-color': '#FF5C5A',
      'circle-pitch-scale': 'map',
      'circle-radius': 8,
    },
    source: 'incidentMarker',
    type: 'circle',
    filter: ['in', 'id', ''],
  })
}

const addBookmarks = (map, points) => {
  addGeoSource(map, 'bookmarks', points)

  addLayer(map, {
    id: 'bookmarks',
    paint: {
      'circle-color': '#FFFFFF',
      'circle-pitch-scale': 'map',
      'circle-radius': 5,
      'circle-stroke-color': '#DDE1E6',
      'circle-stroke-width': 7,
    },
    source: 'bookmarks',
    type: 'circle',
  })
}

const addCurriculum = (map, geometries) => {
  addGeoSource(
    map,
    'curriculum-point',
    geometries.filter((feature) => feature.geometry.type === 'Point')
  )
  addGeoSource(
    map,
    'curriculum-line',
    geometries.filter((feature) => feature.geometry.type === 'LineString')
  )

  addLayer(map, {
    id: 'curriculum-line',
    paint: {
      'line-color': ['get', 'stroke'],
      'line-opacity': 0.75,
      'line-width': 5,
    },
    source: 'curriculum-line',
    type: 'line',
  })

  addLayer(map, {
    id: 'curriculum-point',
    paint: {
      'circle-color': '#FFFFFF',
      'circle-pitch-scale': 'map',
      'circle-radius': 5,
      'circle-stroke-color': ['get', 'stroke'],
      'circle-stroke-width': 7,
    },
    source: 'curriculum-point',
    type: 'circle',
  })
}

const filterCurriculumItems = (ids, incidentId, map) => {
  const filter = ['in', 'id', ...ids]
  map.setFilter('curriculum-line', filter)
  map.setFilter('curriculum-point', filter)
  map.setFilter('selectedMarker', ['in', 'id', incidentId])
}

const removeFilter = (map) => {
  map.setFilter('curriculum-line', null)
  map.setFilter('curriculum-point', null)
  map.setFilter('selectedMarker', ['in', 'id', ''])
}

const addGeoSource = (map, id, features) => {
  const data = { features, type: 'FeatureCollection' }
  const source = map.getSource(id)

  if (source) {
    source.setData(data)
  } else {
    map.addSource(id, {
      data,
      type: 'geojson',
    })
  }
}

const addLayer = (map, layer) => {
  if (!map.getLayer(layer.id)) {
    map.addLayer(layer)
  }
}

const zoomToGeoSource = (map, feature) => {
  const coordinates =
    feature.type === 'MultiLineString'
      ? feature.coordinates[0][0]
      : feature.coordinates[0]
  const bounds = feature.coordinates.reduce(function (bounds, coord) {
    try {
      bounds = bounds.extend(coord)
    } catch (err) {
      console.error(err)
    }
    return bounds
  }, new mapboxgl.LngLatBounds(coordinates, coordinates))

  map.fitBounds(bounds, {
    padding: 200,
  })
}

export default Map

import React from 'react'
import { Typography } from '@material-ui/core'
import style from './style.less'

type UserCardProps = {
  user: {
    firstName: string
    lastName: string
    email: string
  }
}

const UserCard: React.FunctionComponent<UserCardProps> = ({ user }) => {
  return (
    <div className={style.userCard}>
      <div className={style.card}>
        <div className={style.state}>
          <div className={style.cardText}>ACTIVE</div>
        </div>
        <div className={style.content}>
          <Typography
            style={{ fontWeight: 700, textOverflow: 'ellipsis' }}
            variant="h6"
          >{`${user.lastName.toUpperCase()}, ${user.firstName}`}</Typography>
          <Typography>{user.email}</Typography>
        </div>
      </div>
    </div>
  )
}

export default UserCard

import React from 'react'
import { Redirect } from 'react-router-dom'
import { useQuery, NetworkStatus } from '@apollo/client'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useReactRouterParameters } from '../hooks'
import {
  GET_INCIDENTS,
  GET_GEO_FEATURE_COLLECTION,
  GET_SESSION,
} from '../services/apollo/queries'
import IncidentEditor from '../pages/IncidentEditor'
import { Incident } from '../../types'

const IncidentRoute: React.FunctionComponent = () => {
  const { incidentId, sessionId } = useReactRouterParameters()
  const {
    data,
    loading,
    refetch: refetchIncidents,
    networkStatus: incidentStatus,
  } = useQuery(GET_INCIDENTS, {
    fetchPolicy: 'cache-first',
    variables: {
      sessionId,
      sort: { field: 'startOffset', order: 'ASC' },
    },
  })

  const { data: sessionData, loading: sessionLoading } = useQuery(GET_SESSION, {
    fetchPolicy: 'cache-first',
    variables: {
      id: sessionId,
      sort: {
        field: 'startTimestamp',
        order: 'ASC',
      },
    },
  })

  const {
    data: geoFeatureCollectionResp,
    loading: geoLoading,
    refetch: refetchGeoFeatures,
    networkStatus: geoFeaturesStatus,
  } = useQuery(GET_GEO_FEATURE_COLLECTION, {
    fetchPolicy: 'cache-first',
    variables: {
      sessionId,
    },
  })

  const isLoading = loading || geoLoading || sessionLoading

  if (isLoading) {
    return <CircularProgress />
  }

  const incidents: Incident[] = data.incidents.data

  let incident = {
    endOffset: 5.0,
    id: incidentId,
    sessionId,
    startOffset: 0.1,
  }
  if (incidentId !== 'new') {
    incident = incidents.find((i) => i.id === incidentId)
    if (!incident) {
      const firstIncidentId = incidents.length ? incidents[0].id : 'new'
      return (
        <Redirect to={`/sessions/${sessionId}/incidents/${firstIncidentId}`} />
      )
    }
  }

  const refetch = () => {
    refetchIncidents()
    refetchGeoFeatures()
  }

  return (
    <>
      {(incidentStatus === NetworkStatus.refetch ||
        geoFeaturesStatus === NetworkStatus.refetch) && <CircularProgress />}
      <IncidentEditor
        clearCache={refetch}
        geoFeatureCollection={geoFeatureCollectionResp.geoFeatureCollection}
        incident={incident}
        incidents={incidents}
        sessionDuration={sessionDurationInSeconds(sessionData.session)}
        sessionId={sessionId}
      />
    </>
  )
}

const sessionDurationInSeconds = (session) =>
  Math.abs(new Date(session.endTimestamp) - new Date(session.startTimestamp)) /
  1000

export default IncidentRoute

import React, { ReactElement } from 'react'

const Cloud = (): ReactElement => (
  <div>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 22 16"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M6.88234 0.118437C2.64457 0.84022 -0.333735 4.68816 0.0300416 8.97154C0.393818 13.2549 3.97837 16.0032 8.27718 16H16.4559C19.5178 16 22 14.0601 22 10.9981L21.9958 10.7804C21.8815 7.8194 19.4449 5.454 16.4559 5.454L16.046 5.45312L15.9648 5.23512C14.5499 1.64004 10.7811 -0.545603 6.88234 0.118437ZM14.3425 6.704C14.4565 7.14552 14.8548 7.454 15.3108 7.454H16.4559C18.4133 7.454 20 9.04077 20 10.9981C20 12.9555 18.4133 14 16.4559 14H8.27643C5.01642 14.0024 2.29868 12.0499 2.02287 8.8023C1.74706 5.55472 4.00515 2.63729 7.21815 2.09004C10.4312 1.5428 13.5277 3.54822 14.3425 6.704Z"
        fill="black"
        fillOpacity="0.87"
        fillRule="evenodd"
      />
    </svg>
  </div>
)

export default Cloud

import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import { IntlProvider } from 'react-intl'
import { LanguageContext } from '../../context'
import config from '../../config'

const Preloader: React.FunctionComponent = ({ children }) => {
  const { language } = useContext(LanguageContext)

  const [messages, setMessages] = useState(null)

  useEffect(() => {
    axios.get(`${config.locales.url}/${language}.json`).then((response) => {
      setMessages(response.data)
    })
  }, [language])

  if (!messages) {
    return <CircularProgress />
  }

  return (
    <IntlProvider locale={language} messages={messages}>
      {children}
    </IntlProvider>
  )
}

export default Preloader

import config from '../../config'

const nonJsonResponseError = (data) => {
  if (data.status === 500) {
    throw new Error(data?.statusText)
  }

  return new Promise((resolve) => {
    resolve(data)
  })
}

const get = async (url: string, headers = {}): Promise<any> =>
  fetch(`${config.apiUrl}/yapi/v1/${url}`, {
    credentials: 'same-origin',
    headers,
    method: 'GET',
  })
    .then(nonJsonResponseError)
    .then(async (resolve) => await resolve?.json?.())

const put = async (url: string, headers = {}, data = {}): Promise<any> =>
  fetch(`${config.apiUrl}/yapi/v1/${url}`, {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
    method: 'PUT',
  }).then(nonJsonResponseError)

const post = async (url: string, headers = {}, data = {}): Promise<any> =>
  fetch(`${config.apiUrl}/yapi/v1/${url}`, {
    body: JSON.stringify(data),
    credentials: 'same-origin',
    headers,
    method: 'POST',
  }).then(nonJsonResponseError)

export { get, put, post }

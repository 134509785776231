const EVENT = 'event'

interface trackEventProps {
  action: string
  category: string
  label: string
  value?: string
}

const trackEvent = ({
  action,
  category,
  label,
  value,
}: trackEventProps): void => {
  window?.gtag?.(EVENT, action, {
    event_category: category,
    event_label: label,
    value,
  })
}

export default trackEvent

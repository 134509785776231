import React, { ReactElement, useEffect, useState } from 'react'
import { VideoStreams } from '../../../types'
import { GET_VIDEO_STREAMS } from '../../services/apollo/queries'
import withQuery from '../../services/apollo/withQuery'
import MultiPlayer from '../MultiPlayer'
import Scrubber from '../Scrubber'
import PlaybackBar from '../PlaybackBar'
import TelemetryOverlay from '../TelemetryOverlay'
import style from './style.less'

type VideoPaneProps = {
  data: {
    videoStreams: VideoStreams
  }
  endOffset: number
  incidentIndex: number
  onChange: (any) => void
  startOffset: number
  sessionId: string
  jumpToIncident: (string) => void
  sessionDuration: number
}

export const VideoPane: React.FunctionComponent<VideoPaneProps> = ({
  data,
  endOffset,
  onChange,
  startOffset,
  sessionId,
  sessionDuration,
  incidentIndex,
  incidents,
  jumpToIncident,
}) => {
  const [playing, setPlaying] = useState(false)
  const [scrubberEndOffset, setScrubberEndOffset] = useState(endOffset)
  const [scrubbing, setScrubbing] = useState(false)
  const [currentOffset, setCurrentOffset] = useState(startOffset)
  const [playingBounds, setPlayingBounds] = useState({
    endOffset,
    offset: startOffset,
    startOffset,
  })

  useEffect(() => {
    setCurrentOffset(startOffset)
    setPlayingBounds({ endOffset, offset: startOffset, startOffset })
    setScrubberEndOffset(endOffset)
  }, [endOffset, startOffset])

  if (!data?.videoStreams?.data?.length) {
    return <>No videos</>
  }

  const onProgress = (seconds) => setCurrentOffset(seconds)

  const onJump = (seconds) => {
    const currIncidents = incidents.filter(
      ({ startOffset, endOffset }) =>
        startOffset <= seconds && endOffset >= seconds
    )
    currIncidents.sort(
      (a, b) =>
        Math.abs(a.startOffset - seconds) - Math.abs(b.startOffset - seconds)
    )
    const incident = currIncidents.find((i) => i !== undefined)
    if (incident) {
      jumpToIncident?.(incident.id)
      setPlayingBounds({
        endOffset: incident.endOffset,
        offset: incident.startOffset,
        startOffset: seconds,
      })
    } else {
      setPlayingBounds({
        endOffset: sessionDuration,
        offset: seconds,
        startOffset: seconds,
      })
    }
  }

  const videoStreams = data.videoStreams.data

  return (
    <div className={style.video}>
      <MultiPlayer
        endOffset={scrubberEndOffset}
        onProgress={onProgress}
        playingBounds={playingBounds}
        playingPlaybackBar={playing}
        scrubbing={scrubbing}
        sessionDuration={sessionDuration}
        sessionId={sessionId}
        startOffset={startOffset}
        streams={videoStreams}
        telemetryComponent={TelemetryOverlay}
      />
      <div className={style.videoControls}>
        <div className={style.scrubber}>
          <Scrubber
            currentOffset={currentOffset}
            duration={sessionDuration}
            endOffset={playingBounds.endOffset}
            onEndOffsetChange={(newEndOffset, startOffset) => {
              onChange({
                endOffset: newEndOffset,
              })
              setScrubberEndOffset(newEndOffset)
              setPlayingBounds({
                endOffset: newEndOffset,
                offset: newEndOffset,
                startOffset,
              })
            }}
            onEndScrubbing={() => {
              setScrubbing(false)
            }}
            onStartOffsetChange={(newStartOffset, endOffset) => {
              onChange({
                startOffset: newStartOffset,
              })
              setPlayingBounds({
                endOffset,
                offset: newStartOffset,
                startOffset: newStartOffset,
              })
            }}
            onStartScrubbing={() => {
              setScrubbing(true)
            }}
            startOffset={playingBounds.startOffset}
          />
        </div>
        <div className={style.playbackBar}>
          <PlaybackBar
            currentOffset={currentOffset}
            duration={sessionDuration}
            incidentIndex={incidentIndex}
            incidents={incidents}
            onJumpTo={onJump}
            playing={playing}
            setPlaying={setPlaying}
          />
        </div>
      </div>
    </div>
  )
}

const VideoPaneWithQueryOptions = ({
  sessionId,
  ...props
}: VideoPaneProps): ReactElement =>
  withQuery({
    Component: VideoPane,
    query: GET_VIDEO_STREAMS,
    queryOptions: { variables: { sessionId } },
  })({ sessionId, ...props })

export default VideoPaneWithQueryOptions

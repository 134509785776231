// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__incidentPane--ZVJ5K {\n  flex-grow: 1;\n  overflow: auto;\n  padding: 24px;\n}\n.style__header--wwQF0 {\n  font-size: 20px;\n  font-weight: 500;\n  margin-bottom: 8px;\n  margin-top: 32px;\n}\n.style__header--wwQF0:first-child {\n  margin-top: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/IncidentPane/style.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,cAAA;EACA,aAAA;AAAJ;AAJA;EAQI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;AADJ;AAGI;EACE,aAAA;AADN","sourcesContent":[":local {\n  .incidentPane {\n    flex-grow: 1;\n    overflow: auto;\n    padding: 24px;\n  }\n\n  .header {\n    font-size: 20px;\n    font-weight: 500;\n    margin-bottom: 8px;\n    margin-top: 32px;\n\n    &:first-child {\n      margin-top: 0;\n    }\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"incidentPane": "style__incidentPane--ZVJ5K",
	"header": "style__header--wwQF0"
};
export default ___CSS_LOADER_EXPORT___;

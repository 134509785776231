import React, { ReactChild, ReactChildren, ReactElement } from 'react'
import Divider from '@material-ui/core/Divider'
import Menu from '../Menu'
import style from './style.less'

interface PageContainerProps {
  children: ReactChild | ReactChildren
}

const PageContainer = ({ children }: PageContainerProps): ReactElement => (
  <div className={style.menu}>
    <Menu />
    <Divider light />
    <main className={style.pageContainer}>{children}</main>
  </div>
)

export default PageContainer

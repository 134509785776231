import React, { useState, useEffect } from 'react'
import { Typography, Tabs, Tab, Button } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useHistory } from 'react-router-dom'

import { BackIcon } from '../../assets'
import PageContainer from '../../components/PageContainer'
import { get, post } from '../../services/api/api'
import { useMessage } from '../../hooks'
import UserCard from './components/UserCard'
import Modal from './components/Modal'
import style from './style.less'

const STUDENT_ROLE = 'ds-student'
const DS_ADMIN_ROLE = 'ds-admin'
const INSTRUCTOR_ROLE = 'ds-instructor'

export const Settings: React.FunctionComponent = () => {
  const [value, setValue] = useState(0)
  const [loading, setLoading] = useState(true)
  const [authHeader, setAuthHeader] = useState()
  const [users, setUsers] = useState([])
  const [currentUser, setCurrentUser] = useState({})
  const { getAccessTokenSilently, user } = useAuth0()
  const history = useHistory()
  const message = useMessage()

  const handleChange = (event, newValue) => setValue(newValue)

  const createNewUser = async (newUser) => {
    newUser.partnerId = currentUser.partnerId
    await post('users/invite', authHeader, newUser)
    setUsers([...users, newUser])
  }

  useEffect(() => {
    authHeader && get('users', authHeader).then(setUsers)
  }, [authHeader])

  useEffect(() => {
    if (users.length) {
      setCurrentUser(users.find((u) => u.id === user.sub))
      setLoading(false)
    }
  }, [users])

  useEffect(() => {
    const getToken = async () => {
      const token = await getAccessTokenSilently()
      setAuthHeader({ Authorization: `Bearer ${token}` })
    }
    getToken()
  }, [])

  return (
    <PageContainer>
      <div className={style.settings}>
        <div className={style.navigation}>
          <Button
            className={style.backButton}
            onClick={() => history.push('/sessions/overview')}
          >
            <BackIcon style={{ fill: 'black', marginRight: 8 }} />
            <Typography
              style={{
                fontSize: 14,
                fontWeight: 400,
                letterSpacing: 1.25,
              }}
            >
              {message('pages.Settings.back')}
            </Typography>
          </Button>
          {currentUser.roles?.includes(DS_ADMIN_ROLE) && (
            <div>
              <Tabs
                indicatorColor="primary"
                onChange={handleChange}
                textColor="primary"
                value={value}
              >
                <Tab label={message('pages.Settings.tabs.student.name')} />
                <Tab label={message('pages.Settings.tabs.instructor.name')} />
              </Tabs>
            </div>
          )}
        </div>
        <div className={style.content}>
          {loading ? (
            <CircularProgress style={{ margin: 'auto' }} />
          ) : (
            currentUser.roles?.includes(DS_ADMIN_ROLE) && (
              <>
                {value ? (
                  <InstructorsTab createNewUser={createNewUser} users={users} />
                ) : (
                  <StudentsTab createNewUser={createNewUser} users={users} />
                )}
              </>
            )
          )}
        </div>
      </div>
    </PageContainer>
  )
}

const StudentsTab = ({ users, createNewUser }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const message = useMessage()

  const handleDialogCancel = () => setOpenDialog(false)

  const onSave = (user) =>
    createNewUser({
      roles: [STUDENT_ROLE],
      ...user,
    })

  return (
    <>
      <div>
        <Button
          className={style.addUserButton}
          onClick={() => setOpenDialog(true)}
        >
          <div className={style.add}>
            + {message('pages.Settings.tabs.student.add')}
          </div>
        </Button>
      </div>
      <div className={style.itemListColumn}>
        {users
          .filter((user) => user.roles.includes(STUDENT_ROLE))
          .map((user) => (
            <div className={style.itemBox} key={user.id}>
              <UserCard user={user} />
            </div>
          ))}
      </div>
      <Modal
        description={message('pages.Settings.tabs.student.description')}
        onCancel={handleDialogCancel}
        onSave={onSave}
        open={openDialog}
        title={message('pages.Settings.tabs.student.add')}
      />
    </>
  )
}

const InstructorsTab = ({ users, createNewUser }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const message = useMessage()

  const handleDialogCancel = () => setOpenDialog(false)

  const onSave = (user) =>
    createNewUser({
      roles: [INSTRUCTOR_ROLE],
      ...user,
    })

  return (
    <>
      <div>
        <div
          className={style.addUserButton}
          onClick={() => setOpenDialog(true)}
        >
          <div className={style.add}>
            + {message('pages.Settings.tabs.instructor.add')}
          </div>
        </div>
      </div>
      <div className={style.itemListColumn}>
        {users
          .filter((user) => user.roles.includes(INSTRUCTOR_ROLE))
          .map((user) => (
            <div className={style.itemBox} key={user.id}>
              <UserCard user={user} />
            </div>
          ))}
      </div>
      <Modal
        description={message('pages.Settings.tabs.instructor.description')}
        onCancel={handleDialogCancel}
        onSave={onSave}
        open={openDialog}
        title={message('pages.Settings.tabs.instructor.add')}
      />
    </>
  )
}

export default Settings

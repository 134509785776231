import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import { useMessage } from '../../hooks'
import style from './style.less'

type IncidentCreateProps = {
  onCreate: () => void
  onCancel: () => void
}

const IncidentCreate: React.FunctionComponent<IncidentCreateProps> = ({
  onCreate,
  onCancel,
}) => {
  const [loading, setLoading] = useState(false)
  const message = useMessage()

  const handleCreate = async () => {
    setLoading(true)
    onCreate()
  }

  return (
    <div className={style.container}>
      {loading && <CircularProgress />}
      <div className={style.addNewEvent}>
        <div className={style.addNewEventTitle}>
          {message('components.IncidentCreate.addNewEvent')}
        </div>
        <div className={style.addNewEventDescription}>
          {message('components.IncidentCreate.addNewEventDescription')}
        </div>
        <div className={style.buttonGroup}>
          <Button
            onClick={handleCreate}
            style={{
              background: '#00EE77',
              color: '#000000',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 1.25,
            }}
          >
            {message('components.IncidentCreate.addEvent').toUpperCase()}
          </Button>
          <Button
            onClick={onCancel}
            style={{
              color: '#00EE77',
              fontSize: 14,
              fontWeight: 500,
              letterSpacing: 1.25,
            }}
            type="submit"
          >
            {message('components.IncidentCreate.cancel').toUpperCase()}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default IncidentCreate

import { Autocomplete } from '@material-ui/lab'
import React, { ReactElement } from 'react'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import Select from '@material-ui/core/Select'
import SearchIcon from '@material-ui/icons/Search'
import MenuItem from '@material-ui/core/MenuItem'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, createStyles, Theme, Divider } from '@material-ui/core'
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined'
import FaceIcon from '@material-ui/icons/Face'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import { useMessage } from '../../hooks'

const filter = createFilterOptions()

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    autocomplete: {
      '&[aria-expanded=true]': {
        background: '#FFFFFF',
        boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.54)',
      },
      background: '#F5F5F5',
      color: 'rgba(0, 0, 0, 0.38)',
    },
    formControl: {
      fontSize: 14,
      height: '100%',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: 2,
      textAlign: 'center',
      width: 140,
    },
    inputInput: {
      fontSize: 16,
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    inputRoot: {
      // color: 'inherit',
      height: '100%',
      width: '684px',
    },
    list: {
      '&::-webkit-scrollbar': {
        background: 'transparent',
        width: '0px' /* make scrollbar transparent */,
      },
    },
    paper: {
      boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12)',
      margin: 0,
      maxHeight: '40vh',
      overflow: 'hidden',
    },
    popper: {
      margin: '0 -10px',
      paddingTop: '1px',
    },
    root: {
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
      borderRadius: 4,
      width: 684,
    },
    search: {
      borderRadius: theme.shape.borderRadius,
      height: '56px',
      position: 'relative',
      width: '100%',
    },
    searchIcon: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
      pointerEvents: 'none',
      position: 'absolute',
    },
    tagArray: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      listStyle: 'none',
      margin: 0,
      padding: theme.spacing(0.5),
    },
  })
)

const selectComponent = () => (
  <ArrowDropDownRoundedIcon
    style={{
      bottom: 2,
      color: 'rgba(0, 0, 0, 0.54)',
      position: 'relative',
    }}
  />
)

interface SelectedTagProps {
  id: string
  label: string
  tag: boolean
}

interface SearchFieldProps {
  onChange: (event: React.FormEvent<HTMLInputElement>, value: string) => void
  selectedTags: SelectedTagProps[]
  onModeChange: (string) => void
  mode: string
  options: SelectedTagProps[]
}

const SearchField = ({
  onChange,
  selectedTags,
  onModeChange,
  mode,
  options = [],
}: SearchFieldProps): ReactElement => {
  const classes = useStyles()
  const message = useMessage()

  return (
    <div className={classes.root}>
      <Autocomplete
        autoComplete={true}
        classes={{
          listbox: classes.list,
          paper: classes.paper,
          popper: classes.popper,
          root: classes.autocomplete,
        }}
        filterOptions={(options, params) =>
          filter(options, params).filter(
            (option) => !selectedTags.find((t) => t.id === option.id)
          )
        }
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.label === value.label}
        id="search"
        onChange={(event, value) => onChange(value)}
        options={options}
        renderInput={(params) => (
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                input: classes.inputInput,
                root: classes.inputRoot,
              }}
              inputProps={{
                ...params.inputProps,
                'aria-label': 'search',
              }}
              placeholder={message('components.SearchField.searchPlaceholder')}
              ref={params.InputProps.ref}
            />
            <Select
              className={classes.formControl}
              disableUnderline
              IconComponent={selectComponent}
              id="select-search-mode"
              labelId="select-search-mode"
              onChange={(event) =>
                onModeChange(event.target.value === 10 ? 'drives' : 'events')
              }
              value={mode === 'drives' ? 10 : 20}
            >
              <MenuItem value={10}>
                {message('components.SearchField.showDrives')}
              </MenuItem>
              <MenuItem value={20}>
                {message('components.SearchField.showEvents')}
              </MenuItem>
            </Select>
          </div>
        )}
        renderOption={(option) => (
          <React.Fragment>
            {option.tag ? <LocalOfferOutlinedIcon /> : <FaceIcon />}
            <Divider style={{ color: 'transparent', marginRight: 8 }} />
            {option.label}
          </React.Fragment>
        )}
        style={{ marginTop: 0 }}
      />
    </div>
  )
}

export default SearchField

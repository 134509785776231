// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__incidentNavigation--bWdBk {\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.38);\n  bottom: 0;\n  display: flex;\n  justify-content: space-between;\n  min-height: 96px;\n  padding: 24px;\n}\n.style__incidentNavigationToggle--MrHVq {\n  height: 48px;\n  width: 96px;\n}\n.style__exitButton--SUt3H {\n  color: #00ee77;\n  font-size: 14px;\n  letter-spacing: 1.25px;\n  line-height: 16px;\n  padding: 1rem;\n  position: relative;\n  text-transform: uppercase;\n}\n.style__exitButton--SUt3H:hover {\n  background-color: rgba(255, 255, 255, 0.06);\n}\n", "",{"version":3,"sources":["webpack://./src/components/IncidentNavigation/style.less"],"names":[],"mappings":"AAAA;EAEI,mBAAA;EACA,qCAAA;EACA,SAAA;EACA,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,aAAA;AAAJ;AARA;EAYI,YAAA;EACA,WAAA;AADJ;AAZA;EAiBI,cAAA;EACA,eAAA;EACA,sBAAA;EACA,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,yBAAA;AAFJ;AAII;EACE,2CAAA;AAFN","sourcesContent":[":local {\n  .incidentNavigation {\n    align-items: center;\n    background-color: rgba(0, 0, 0, 0.38);\n    bottom: 0;\n    display: flex;\n    justify-content: space-between;\n    min-height: 96px;\n    padding: 24px;\n  }\n\n  .incidentNavigationToggle {\n    height: 48px;\n    width: 96px;\n  }\n\n  .exitButton {\n    color: #00ee77;\n    font-size: 14px;\n    letter-spacing: 1.25px;\n    line-height: 16px;\n    padding: 1rem;\n    position: relative;\n    text-transform: uppercase;\n\n    &:hover {\n      background-color: rgba(255, 255, 255, 0.06);\n    }\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"incidentNavigation": "style__incidentNavigation--bWdBk",
	"incidentNavigationToggle": "style__incidentNavigationToggle--MrHVq",
	"exitButton": "style__exitButton--SUt3H"
};
export default ___CSS_LOADER_EXPORT___;

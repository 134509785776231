import { lighten } from 'polished'
import React, { ReactElement, useEffect, useState } from 'react'
import classNames from 'classnames'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BrakeIcon,
  GasPedalIcon,
  SpeedIcon,
  SteeringWheelIcon,
} from '../../assets'
import style from './style.less'

const getAccelerationOpacity = (x: number): number =>
  x > 0 && x <= 0.6
    ? 0.1
    : x > 0.6 && x <= 1
      ? 0.2
      : x > 1 && x <= 1.6
        ? 0.3
        : x > 1.6 && x <= 2
          ? 0.4
          : x > 2 && x <= 2.5
            ? 0.5
            : x > 2.5 && x <= 3
              ? 0.6
              : x > 3 && x <= 3.5
                ? 0.7
                : x > 3.5 && x <= 4
                  ? 0.8
                  : x > 4 && x <= 4.5
                    ? 0.9
                    : x > 4.5
                      ? 1
                      : -1

const getAccelerationAngle = (x: number): number =>
  x > 0 && x <= 0.6
    ? -160
    : x > 0.6 && x <= 1
      ? -130.2
      : x > 1 && x <= 1.6
        ? -100
        : x > 1.6 && x <= 2
          ? -70
          : x > 2 && x <= 2.5
            ? -40
            : x > 2.5 && x <= 3
              ? -10
              : x > 3 && x <= 3.5
                ? 20
                : x > 3.5 && x <= 4
                  ? 50
                  : x > 4 && x <= 4.5
                    ? 60
                    : x > 4.5
                      ? 65
                      : -160

const TURN_SIGNAL_LEFT = 'LEFT'
const TURN_SIGNAL_RIGHT = 'RIGHT'

interface TelemetryProps {
  telemetry: any
}

const Telemetry = ({ telemetry }: TelemetryProps): ReactElement => {
  const [accelerationAngle, setAccelerationAngle] = useState(
    getAccelerationAngle(Number(telemetry?.acceleration?.toFixed(1)))
  )

  useEffect(() => {
    setAccelerationAngle(
      getAccelerationAngle(Number(telemetry?.acceleration?.toFixed(1)))
    )
  }, [telemetry])

  return (
    <div className={style.telemetry}>
      <div className={style.speedControl}>
        <div className={style.speed}>{Math.floor(telemetry?.speed)}</div>
      </div>
      {/* <div className={style.topContentGroup}>
        <div className={style.pedalBox}>
          <BrakeIcon
            className={classNames(
              style.break,
              telemetry?.brakePressed && style.activeControl
            )}
          />
          <GasPedalIcon
            className={style.gasPedal}
            style={{
              fill:
                telemetry?.gasPedal > 0
                  ? lighten(telemetry?.gasPedal, '#00EE77')
                  : 'rgba(255, 255, 255, 0.7)',
            }}
          />
        </div>
        <div className={style.animatedIconBox}>
          <SteeringWheelIcon
            className={classNames(style.animatedSteeringWheel)}
            style={{
              fill: `${lighten(
                1 - Math.abs(-1 * telemetry?.steeringWheelAngle) / 180,
                '#00EE77'
              )}`,
              transform: `rotate(${-1 * telemetry?.steeringWheelAngle || 0
                }deg)`,
            }}
          />
        </div>
        <div className={style.animatedIconBox}>
          <svg
            className={style.animatedAcceleration}
            fill="none"
            height="24"
            style={{
              fill: `${getAccelerationOpacity(
                Number(telemetry?.acceleration?.toFixed(1))
              ) > 0
                ? lighten(
                  getAccelerationOpacity(
                    Number(telemetry?.acceleration?.toFixed(1))
                  ),
                  '#00EE77'
                )
                : 'rgba(255, 255, 255)'
                }`,
            }}
            viewBox="0 0 24 24"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M 19.037 9.751 C 20.308 12.531 19.836 16.865 18.93 18 L 12 18 L 5.07 18 C 3.532 15.333 3.727 12.333 5.075 10 C 6.424 7.668 8.807 5.737 11.887 5.758 C 15.456 5.782 17.89 7.242 19.037 9.751 Z M 20.41 7.933 C 17.291 2.955 10.249 3.396 7.257 4.943 C 4.265 6.489 2.222 9.728 2.043 13.572 C 1.999 14.521 2.09 15.466 2.31 16.379 C 2.53 17.293 2.879 18.176 3.35 19 C 3.528 19.308 3.78 19.557 4.077 19.729 C 4.374 19.902 4.715 19.998 5.07 20 L 11.995 20 L 18.92 20 C 19.279 20.002 19.624 19.907 19.924 19.734 C 20.225 19.561 20.481 19.311 20.66 19 C 21.6 17.373 21.886 15.508 21.941 13.669 C 21.989 12.074 21.535 9.729 20.41 7.933 Z"
              fill-rule="evenodd"
            />
            <path
              clip-rule="evenodd"
              d="M 10.59 15.41 C 11.371 16.192 12.639 16.192 13.42 15.41 L 17.2 8.958 L 10.59 12.58 C 9.808 13.361 9.808 14.629 10.59 15.41 Z"
              fill-rule="evenodd"
              style={{
                transform: `${accelerationAngle && 'rotate(' + accelerationAngle + 'deg)'
                  }`,
                transformOrigin: '53% 57%',
              }}
            />
          </svg>
        </div>
      </div> */}
      <div className={style.contentGroup}>
        <div className={style.gearGroup}>
          <div
            className={style.gear}
            style={{
              color: telemetry?.gear === 0 && 'rgba(255, 255, 255, 0.87)',
            }}
          >
            P
          </div>
          <div
            className={style.gear}
            style={{
              color: telemetry?.gear === 1 && 'rgba(255, 255, 255, 0.87)',
            }}
          >
            R
          </div>
          <div
            className={style.gear}
            style={{
              color: telemetry?.gear === 2 && 'rgba(255, 255, 255, 0.87)',
            }}
          >
            N
          </div>
          <div
            className={style.gear}
            style={{
              color: telemetry?.gear === 3 && 'rgba(255, 255, 255, 0.87)',
            }}
          >
            D
          </div>
        </div>
        <div className={style.steeringAndAcceleration}>
          <div className={style.content}>
            <SteeringWheelIcon className={style.steeringWheel} />
            <div style={{ marginLeft: 15, position: 'relative' }}>
              <div
                style={{ position: 'absolute', top: 4 }}
              >{`${telemetry?.steeringWheelAngle.toFixed(1)}°`}</div>
            </div>
          </div>
          <div className={style.content} style={{ marginLeft: 50 }}>
            <SpeedIcon className={style.acceleration} />
            <div style={{ marginLeft: 15, position: 'relative' }}>
              <div style={{ position: 'absolute', top: 4 }}>{`${telemetry?.acceleration
                ? telemetry?.acceleration?.toFixed(1)
                : 0
                }m/s²`}</div>
            </div>
          </div>
        </div>
        <div className={style.turnSignalsBox}>
          <div className={style.turnSignals}>
            <ArrowLeftIcon
              className={classNames(
                style.turnSignal,
                telemetry?.turnSignals === TURN_SIGNAL_LEFT
                  ? style.activeControl : style.inactiveControl
              )}
            />
            <ArrowRightIcon
              className={classNames(
                style.turnSignal,
                telemetry?.turnSignals === TURN_SIGNAL_RIGHT
                  ? style.activeControl : style.inactiveControl
              )}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Telemetry

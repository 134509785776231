import React from 'react'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import fonts from '../../assets/fonts/basisGrotesque'

const theme = createTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
        color: 'rgba(0,0,0,0.87)',
      },
      colorSecondary: {
        color: '#00eeff',
      },
    },
    MuiAvatar: {
      root: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '16px',
        textAlign: 'center',
      },
    },
    MuiBadge: {
      badge: {
        fontSize: '12px',
        fontWeight: 700,
        lineHeight: '2em',
      },
      colorPrimary: {
        color: 'rgba(0,0,0,0.87)',
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: 'rgba(0,0,0,0.87)',
      },
    },
    MuiButton: {
      contained: {
        '&$focusVisible': {
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.15)',
        },
        '&:hover': {
          boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)',
        },
        boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)',
      },
      containedSizeLarge: {
        fontSize: '14px',
        padding: '18px 16px',
      },
      containedSizeSmall: {
        fontSize: '14px',
        padding: '12px 16px',
      },
      label: {
        height: '12px',
      },
      outlinedPrimary: {
        '&:hover': {
          border: '1px solid rgba(0,127,64)',
        },
        border: '1px solid rgba(0,127,64,0.5)',
        color: 'rgba(0,127,64,0.87)',
      },
      outlinedSecondary: {
        '&:hover': {
          border: '1px solid rgba(0,238,119)',
        },
        border: '1px solid rgba(0,238,119)',
        color: 'rgba(0,238,119,0.87)',
      },
      outlinedSizeLarge: {
        fontSize: '14px',
        padding: '17px 16px',
      },
      outlinedSizeSmall: {
        fontSize: '14px',
        padding: '11px 16px',
      },
      text: {
        padding: '18px 16px',
      },
      textPrimary: {
        color: 'rgba(0,127,64,0.87)',
      },
      textSecondary: {
        color: 'rgba(0,238,119,0.87)',
      },
      textSizeLarge: {
        fontSize: '14px',
        padding: '18px 16px',
      },
      textSizeSmall: {
        fontSize: '14px',
        padding: '12px 16px',
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#00EE77',
      },
      root: {
        '&$checked': {
          color: '#00EE77',
        },
        color: 'rgba(0,0,0,0.87)',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': fonts,
      },
    },
    MuiFab: {
      root: {
        '&:active': {
          boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.12)',
        },
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.1)',
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: 'rgba(0,238,119,0.87)',
      },
      root: {
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.06)',
        },
        color: 'rgba(0,0,0,0.87)',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 0px 2px 0px rgba(0,0,0,0.1)',
      },
      elevation12: {
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.12)',
      },
      elevation2: {
        boxShadow: '0px 0px 6px 0px rgba(0,0,0,0.15)',
      },
      elevation24: {
        boxShadow: '0px 0px 35px 0px rgba(0,0,0,0.18)',
      },
      elevation4: {
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.12)',
      },
    },
    MuiSelect: {
      icon: {
        color: 'black',
      },
    },
    MuiSnackbarContent: {
      action: {
        color: '#00EE77',
      },
    },
    MuiTableCell: {
      body: {
        borderBottom: 'none !important',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      root: {
        height: 52,
        padding: 0,
      },
    },
    MuiTableRow: {
      root: {
        borderBottom: 'none !important',
      },
    },
    MuiTableSortLabel: {
      root: {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: 14,
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 14,
        letterSpacing: 0.25,
      },
    },
  },
  palette: {
    action: {
      activatedOpacity: 0.12,
      active: '#00EE77',
      disabledOpacity: 0.38,
      focusOpacity: 0.12,
      hoverOpacity: 0.06,
      selected: 'rgba(0,238,119,0.08)',
      selectedOpacity: 0.08,
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    common: {
      black: '#000',
      white: '#fff',
    },
    contrastThreshold: 3,
    error: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#A90706',
      light: '#D33130',
      main: '#CA0807',
    },
    info: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#182123',
      light: '#7A8284',
      main: '#223033',
    },
    primary: {
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#0DE070',
      light: '#00EE77',
      main: '#00EE77',
    },
    secondary: {
      contrastText: 'rgba(255,255,255,0.87)',
      dark: '#182123',
      light: '#7A8284',
      main: '#223033',
    },
    success: {
      contrastText: '#rgba(0,0,0,0.87)',
      dark: '#0DE070',
      light: '#00EE77',
      main: '#00EE77',
    },
    type: 'light',
    warning: {
      contrastText: 'rgba(0,0,0,0.87)',
      dark: '#FFA000',
      light: '#FFD54F',
      main: '#FFC107',
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    fontFamily:
      '"Basis Grotesque Pro", "Basis Grotesque", Verdana, Geneva, sans-serif',
    fontSize: 14,
    htmlFontSize: 14,
  },
})

const LightTheme: React.FunctionComponent = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default LightTheme

import { useIntl } from 'react-intl'

const useMessage = (): any => {
  const intl = useIntl()
  return (id: string, values?: any) =>
    intl.formatMessage(
      {
        id,
      },
      values
    )
}

export default useMessage

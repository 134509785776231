import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import IconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useReactRouterParameters, useMessage } from '../../hooks'
import GA_EVENT_ACTIONS from '../../tracking/consts/eventActions'
import GA_EVENT_CATEGORIES from '../../tracking/consts/eventCategories'
import trackEvent from '../../tracking/util/trackEvent'
import style from './style.less'

type IncidentHeaderProps = {
  incidentIds: string[]
  currentPage: number
  triggerSave: () => void
  triggerCreate: () => void
  pageCount: number
  sessionName: string
}

const IncidentHeader: React.FunctionComponent<IncidentHeaderProps> = ({
  currentPage,
  incidentIds,
  triggerCreate,
  pageCount,
  sessionName,
}) => {
  const { sessionId } = useReactRouterParameters()
  const history = useHistory()
  const message = useMessage()

  const incidentIndex = currentPage - 1

  const handleNextIncident = async () => {
    const nextIncidentId = incidentIds[(incidentIndex + 1) % incidentIds.length]
    trackEvent({
      action: GA_EVENT_ACTIONS.INCIDENT_EDITOR.NEXT_INCIDENT,
      category: GA_EVENT_CATEGORIES.INCIDENT_EDITOR,
      label: nextIncidentId,
    })
    history.push(`/sessions/${sessionId}/incidents/${nextIncidentId}`)
  }

  const handlePreviousIncident = async () => {
    const previousIncident =
      incidentIds[
        incidentIndex - 1 < 0 ? incidentIds.length - 1 : incidentIndex - 1
      ]
    trackEvent({
      action: GA_EVENT_ACTIONS.INCIDENT_EDITOR.PREVIOUS_INCIDENT,
      category: GA_EVENT_CATEGORIES.INCIDENT_EDITOR,
      label: previousIncident,
    })
    history.push(`/sessions/${sessionId}/incidents/${previousIncident}`)
  }

  const handleAddIncident = async () => {
    triggerCreate()
  }

  document.body.onkeydown = async (e) => {
    if (e.code === 'ArrowRight') {
      await handleNextIncident()
    } else if (e.code === 'ArrowLeft') {
      await handlePreviousIncident()
    }
  }

  return (
    <div className={style.incidentHeader}>
      {sessionName}
      <div className={style.eventContainer}>
        <div className={style.flex}>
          <Typography style={{ lineHeight: '24px' }} variant="h5">
            {message('pages.IncidentEditor.incidentHeader', {
              currentPage,
              pageCount,
            })}
          </Typography>
          <IconButton
            onClick={handleAddIncident}
            style={{ bottom: 15, left: 8, position: 'relative' }}
          >
            <MoreHorizIcon style={{ color: 'rgba(255, 255, 255, 0.7)' }} />
          </IconButton>
        </div>
        <ButtonGroup style={{ bottom: 15, position: 'relative' }}>
          <IconButton color="primary" onClick={handlePreviousIncident}>
            <ArrowBackIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleNextIncident}>
            <ArrowForwardIcon />
          </IconButton>
        </ButtonGroup>
      </div>
    </div>
  )
}

export default IncidentHeader

import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Tag from '../Tag'
import { GET_INCIDENT_OUTCOMES } from '../../services/apollo/queries'
import { CREATE_INCIDENT_OUTCOME } from '../../services/apollo/mutations'
import withQuery from '../../services/apollo/withQuery'
import { IncidentOutcome, IncidentOutcomes } from '../../../types'
import { useMessage } from '../../hooks'

type OutcomeListProps = {
  data: {
    incidentOutcomes: IncidentOutcomes
  }
  onChange?: (outcomeIds: string[]) => void
  outcomeIds: string[]
}

const sortOutcomes = ({ label: labelA }, { label: labelB }) => {
  if (labelA < labelB) {
    return -1
  }
  if (labelA > labelB) {
    return 1
  }
  return 0
}

export const OutcomeList: React.FunctionComponent<OutcomeListProps> = ({
  data,
  onChange,
  outcomeIds = [],
}) => {
  const messages = useMessage()

  const [selectedTags, setSelectedTags] = useState(
    Array.isArray(outcomeIds) ? outcomeIds : []
  )
  const [open, toggleOpen] = React.useState(false)
  const [dialogValue, setDialogValue] = React.useState({ label: '' })
  const outcomes: IncidentOutcome[] = data.incidentOutcomes.data
  const [createIncidentOutcome] = useMutation(CREATE_INCIDENT_OUTCOME, {
    onCompleted: (rest) => {
      console.log('onCompleted', rest)
    },
  })

  const handleClose = () => {
    setDialogValue({
      label: '',
    })

    toggleOpen(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    handleClose()
  }

  const handleClick = (outcome: IncidentOutcome) => {
    let tags = []
    if (!selectedTags.includes(outcome.id)) {
      tags = [outcome.id].concat(selectedTags)
    } else {
      // remove element
      const index = selectedTags.indexOf(outcome.id)
      tags = selectedTags.slice()
      tags.splice(index, 1)
    }
    setSelectedTags(tags)
    if (onChange) {
      onChange(tags)
    }
  }

  const handleAddTag = () => {
    return createIncidentOutcome({
      variables: {
        incidentOutcomeInput: {
          label: dialogValue.label,
        },
      },
    }).then((resp) => {
      outcomes.push(resp.data.createIncidentOutcome)
      outcomes.sort(sortOutcomes)
    })
  }

  return (
    <>
      {outcomes
        .filter((outcome) => selectedTags.includes(outcome.id))
        .map((trigger, index) => (
          <Tag
            key={index}
            label={trigger.label}
            onDelete={() => handleClick(trigger)}
            selected={true}
          />
        ))}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={handleClose}
        open={open}
      >
        <form onSubmit={handleSubmit} style={{ background: '#282828' }}>
          <DialogTitle id="form-dialog-title">
            {messages('components.AddTagDialog.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {messages('components.AddTagDialog.content')}
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              id="name"
              label={messages('components.AddTagDialog.label')}
              margin="dense"
              onChange={(event) =>
                setDialogValue({ label: event.target.value })
              }
              type="text"
              value={dialogValue.label}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} style={{ color: '#00EE77' }}>
              {messages('components.AddTagDialog.btnCancel')}
            </Button>
            <Button
              onClick={handleAddTag}
              style={{ color: '#00EE77' }}
              type="submit"
            >
              {messages('components.AddTagDialog.btnAdd')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default withQuery({
  Component: OutcomeList,
  query: GET_INCIDENT_OUTCOMES,
})

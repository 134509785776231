// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__navigation--U857k {\n  display: flex;\n  justify-content: flex-end;\n  position: relative;\n  bottom: 13rem;\n  left: -1rem;\n}\n.style__legend--P6Wg0 {\n  position: absolute;\n  top: 40px;\n  left: 40px;\n}\n.style__legendContent--W92sQ {\n  display: flex;\n  flex-direction: column;\n  color: white;\n  align-items: flex-start;\n  font-size: 14px;\n  font-weight: bold;\n}\n.style__legendItem--NUqrU {\n  padding: 8px;\n  margin: 4px;\n  box-sizing: border-box;\n  border-radius: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Map/style.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,WAAA;AAAJ;AANA;EASI,kBAAA;EACA,SAAA;EACA,UAAA;AAAJ;AAXA;EAeI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;AADJ;AAnBA;EAwBI,YAAA;EACA,WAAA;EACA,sBAAA;EACA,mBAAA;AAFJ","sourcesContent":[":local {\n  .navigation {\n    display: flex;\n    justify-content: flex-end;\n    position: relative;\n    bottom: 13rem;\n    left: -1rem;\n  }\n  .legend {\n    position: absolute;\n    top: 40px;\n    left: 40px;\n  }\n\n  .legendContent {\n    display: flex;\n    flex-direction: column;\n    color: white;\n    align-items: flex-start;\n    font-size: 14px;\n    font-weight: bold;\n  }\n\n  .legendItem {\n    padding: 8px;\n    margin: 4px;\n    box-sizing: border-box;\n    border-radius: 24px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "style__navigation--U857k",
	"legend": "style__legend--P6Wg0",
	"legendContent": "style__legendContent--W92sQ",
	"legendItem": "style__legendItem--NUqrU"
};
export default ___CSS_LOADER_EXPORT___;

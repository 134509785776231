import React, { useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import ReactPlayer from 'react-player'
import { PlayIcon } from '../../assets'
import style from './style.less'

type VideoPlayerProps = {
  buffering: string[]
  cameraPosition: string
  setBuffering?: (any) => void
  onLoading?: (boolean) => void
  onProgress?: (number) => void
  playing: boolean
  scrubbing: boolean
  url: string
  playingBounds: {
    starOffset: number
    endOffset: number
    offset: number
  }
}

const MAX_BUFFER_LENGHT_SECONDS = 20
const MAX_BUFFER_SIZE_BYTES = 10 * 1024 * 1024

const VideoPlayer: React.FunctionComponent<VideoPlayerProps> = ({
  buffering,
  cameraPosition,
  onLoading,
  playing,
  scrubbing,
  onProgress,
  url,
  playingBounds,
  setPlaying,
  loading,
  setBuffering,
  absolutePositioning = { top: 0 },
  token,
}) => {
  const player = useRef(null)
  const [offset, setOffset] = useState(0)
  const [ready, setReady] = useState(false)
  const [seeking, setSeeking] = useState(false)
  const [scrubbedToEnd, setScrubbedToEnd] = useState(false)

  document.body.onkeyup = (e) => {
    if (e.code === 'Space') {
      setPlaying(!playing)
    }
  }

  // seek on scrubbing & restart on navigation
  useEffect(() => {
    if (player.current) {
      if (scrubbing && scrubbedToEnd) {
        setScrubbedToEnd(false)
        seekTo(playingBounds.startOffset)
      } else {
        seekTo(playingBounds.offset)
      }
    }
  }, [scrubbing, playingBounds.startOffset, playingBounds.offset, player])

  useEffect(() => {
    if (scrubbing || scrubbedToEnd) {
      seekTo(playingBounds.endOffset)
      scrubbing && !scrubbedToEnd && setScrubbedToEnd(true)
    }
  }, [scrubbing, playingBounds.endOffset])

  const limitPlaybackBounds = useCallback(() => {
    if (!ready || scrubbing) {
      return
    }
    if (offset < playingBounds.startOffset) {
      seekTo(playingBounds.startOffset)
    }
    // loop
    if (offset > playingBounds.endOffset) {
      seekTo(playingBounds.startOffset)
    }
  }, [
    playingBounds.startOffset,
    scrubbing,
    playingBounds.endOffset,
    offset,
    ready,
  ])

  useEffect(() => {
    limitPlaybackBounds()
  }, [
    playingBounds.startOffset,
    playingBounds.endOffset,
    offset,
    ready,
    scrubbing,
    limitPlaybackBounds,
  ])

  useEffect(() => {
    onLoading(!ready || seeking)
  }, [onLoading, ready, seeking])

  const seekTo = (seconds: number) => {
    setSeeking(true)
    player.current.seekTo(seconds, 'seconds')
  }

  const handleReady = () => {
    setReady(true)
  }

  // Called on seek completion
  const handleSeek = (seconds: number) => {
    setOffset(seconds)
    setSeeking(false)
  }

  const handlePlay = () => {
    if (
      Object.keys(buffering).length === 0 &&
      offset >= playingBounds.endOffset
    ) {
      seekTo(playingBounds.startOffset)
    }
  }

  // Called during playback
  // Interval rate set by `ReactPlayer.playedSeconds` property. Currently 10ms
  const handleProgress = ({ playedSeconds }) => {
    setOffset(playedSeconds)
    onProgress && onProgress(playedSeconds)
  }

  return (
    <div className={style.aspectRatio}>
      <div
        className={classNames(
          style.overlay,
          playing &&
            cameraPosition === 'CAM_FRONT_CENTER' &&
            style.overlayDisabled
        )}
        onClick={() => {
          setPlaying(!playing)
        }}
      >
        {!loading && !playing && cameraPosition === 'CAM_FRONT_CENTER' && (
          <PlayIcon style={{ height: '4rem', width: '4rem' }} />
        )}
      </div>
      {token && (
        <ReactPlayer
          className={style.player}
          config={{
            file: {
              forceHLS: true,
              hlsOptions: {
                maxBufferLength: MAX_BUFFER_LENGHT_SECONDS,
                maxBufferSize: MAX_BUFFER_SIZE_BYTES,
                maxFragLookUpTolerance: 0,
                startPosition: playingBounds.startOffset,
                xhrSetup: (xhr) => {
                  xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                },
              },
            },
          }}
          controls={false}
          height="100%"
          onBuffer={() => {
            setBuffering(true)
          }}
          onError={console.error}
          onPlay={handlePlay}
          onProgress={handleProgress}
          onReady={() => {
            setBuffering(false)
            handleReady()
          }}
          onSeek={handleSeek}
          playing={playing}
          progressInterval={40}
          ref={player}
          style={absolutePositioning}
          url={url}
          width={'100%'}
        />
      )}
    </div>
  )
}

export default VideoPlayer

import React, { useState, MouseEvent } from 'react'
import { Waypoint } from 'react-waypoint'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import useReactRouter from 'use-react-router'
import { TableSortLabel, Tooltip, Typography, Divider } from '@material-ui/core'
import { Sun, Cloud, Storm, Rain, Snow } from '../../assets/icons'
import { EditIcon } from '../../assets'
import GA_EVENT_ACTIONS from '../../tracking/consts/eventActions'
import GA_EVENT_CATEGORIES from '../../tracking/consts/eventCategories'
import trackEvent from '../../tracking/util/trackEvent'
import style from './style.less'

type Order = 'asc' | 'desc'

interface YaakTableProps {
  // eslint-disable-next-line no-use-before-define
  classes?: ReturnType<typeof useStyles>
  headers: string[]
  rows: (string | any)[][]
  title: string
  order?: Order
  orderBy?: any
  onRequestSort?: (event: MouseEvent<unknown>, property: any) => void
}

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  if (typeof a[orderBy] === 'object' || typeof b[orderBy] === 'object') {
    if (
      b[orderBy][Object.keys(b[orderBy])[0]].condition <
      a[orderBy][Object.keys(a[orderBy])[0]].condition
    ) {
      return -1
    }
    if (
      b[orderBy][Object.keys(b[orderBy])[0]].condition >
      a[orderBy][Object.keys(a[orderBy])[0]].condition
    ) {
      return 1
    }
  }
  return 0
}

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const YaakTableHead: React.FunctionComponent<YaakTableProps> = (props) => {
  const { classes, headers, order, orderBy, onRequestSort } = props
  const createSortHandler = (property: any) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableHead>
      <TableRow>
        {headers.map((label, headerIndex) => (
          <TableCell
            key={headerIndex}
            sortDirection={orderBy === headerIndex.toString() ? order : false}
          >
            <TableSortLabel
              active={orderBy === headerIndex.toString()}
              direction={orderBy === headerIndex.toString() ? order : 'asc'}
              onClick={createSortHandler(headerIndex)}
            >
              {label}
              {orderBy === headerIndex.toString() ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  )
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      top: 'auto',
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  })
)

const YaakTable: React.FunctionComponent<YaakTableProps> = ({
  headers,
  title,
  rows,
  fetchMore,
  loading,
}) => {
  const classes = useStyles()
  const { history } = useReactRouter()

  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof any>('')

  const handleClick = (cell) => {
    trackEvent({
      action: GA_EVENT_ACTIONS.SESSION_OVERVIEW.EDIT_SESSION,
      category: GA_EVENT_CATEGORIES.OVERVIEW,
      label: cell.sessionId,
    })
    cell.sessionId
      ? history.push(`/sessions/${cell.sessionId}/incidents/${cell.id}`)
      : history.push(`/sessions/${cell.id}/incidents`)
  }

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  // @TODO: This is obviously a mock. Change as soon as real Data is available
  const checkWeather = (type) => {
    switch (type) {
      case 'sunny':
        return <Sun />
      case 'cloudy':
        return <Cloud />
      case 'rainy':
        return <Rain />
      case 'stormy':
        return <Storm />
      case 'snowy':
        return <Snow />
      default:
        break
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <TableContainer style={{ maxHeight: 'calc(100vh - 255px)' }}>
        <Table aria-label={title} stickyHeader>
          <YaakTableHead
            classes={classes}
            headers={headers}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy.toString()}
            rows={rows}
            title={title}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map(
              (row, rowIndex) => (
                <TableRow
                  className={style.tableRow}
                  hover
                  key={rowIndex}
                  style={{
                    background: rowIndex % 2 ? '#FAFAFA' : 'inheritend',
                    maxHeight: 52,
                  }}
                >
                  {row.map((cell, cellIndex) => {
                    return (
                      <TableCell
                        key={cellIndex}
                        style={{
                          height: 'auto !important',
                        }}
                      >
                        {typeof cell === 'boolean' && (
                          <div
                            style={{
                              display: 'block',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                            }}
                          >
                          </div>
                        )}
                        {typeof cell === 'string' && (
                          <div>
                            <Tooltip title={cell}>
                              <Typography
                                style={{
                                  display: 'block',
                                  fontFeatureSettings: "'tnum' on, 'lnum' on",
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                {cell}
                              </Typography>
                            </Tooltip>
                          </div>
                        )}
                        {typeof cell === 'object' && cell.id && (
                          <IconButton onClick={() => handleClick(cell)}>
                            <EditIcon
                              className="editSessionIcon"
                              color={'primary'}
                              style={{
                                fill: '#00C361',
                                height: 24,
                                width: 24,
                              }}
                            />
                          </IconButton>
                        )}
                        {cell && typeof cell === 'object' && cell.weather && (
                          <div
                            style={{
                              display: 'inline-flex',
                            }}
                          >
                            {checkWeather(cell.weather.type)}
                            <Divider
                              style={{
                                backgroundColor: 'transparent',
                                width: '17px',
                              }}
                            />
                            {cell.weather.condition}
                          </div>
                        )}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        {loading ? (
          <div className={style.loadingRow}>
            <CircularProgress />
          </div>
        ) : (
          <Waypoint onEnter={() => fetchMore(rows.length)} />
        )}
      </TableContainer>
    </div>
  )
}

export default YaakTable

import React, { ReactElement } from 'react'
import { AddIcon, DecreaseIcon, Locate } from '../../assets'
import style from './style.less'

interface MapNavigationProps {
  onCenter: () => void
  onDecreaseZoom: () => void
  onIncreaseZoom: () => void
}

const MapNavigation = ({
  onCenter,
  onDecreaseZoom,
  onIncreaseZoom,
}: MapNavigationProps): ReactElement => (
  <div className={style.mapNavigation}>
    <div className={style.zoomGroup}>
      <button className={style.navButton} onClick={onIncreaseZoom}>
        <AddIcon className={style.icon} />
      </button>
      <button className={style.navButton} onClick={onDecreaseZoom}>
        <DecreaseIcon className={style.icon} />
      </button>
    </div>
    <button className={style.navButton} onClick={onCenter}>
      <Locate className={style.centerIcon} />
    </button>
  </div>
)

export default MapNavigation

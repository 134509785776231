import React, { ReactElement } from 'react'
import { Redirect } from 'react-router-dom'
import { useCookies } from 'react-cookie'

const Login = (): ReactElement => {
  function parseUrl(url) {
    const authObj = {}
    const array = url
      .split('#')[1]
      .split('&')
      .map((e) => e.split('='))
    array.forEach((element) => {
      authObj[element[0]] = element[1]
    })
    return authObj
  }
  const isLoggedin: { [key: string]: string } = parseUrl(window.location.href)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies()
  setCookie('accessToken', isLoggedin.access_token, { path: '/' })
  setCookie('idToken', isLoggedin.id_token, { path: '/' })
  return <Redirect to={isLoggedin ? '/' : '/login'} />
}

export default Login

import classNames from 'classnames'
import React, { useState, useContext, useEffect } from 'react'
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import { createStyles, withStyles } from '@material-ui/core'
import {
  Calendar as MuiCalendar,
  MuiPickersContext,
} from '@material-ui/pickers'

import { useMessage, useComponentVisible } from '../../hooks'
import style from './style.less'

const styles = createStyles(() => ({
  cap: {
    background: '#00EE77',
    borderRadius: '50% 50% 50% 50%',
  },
  clearCalendar: {
    cursor: 'pointer',
    fontSize: 14,
  },
  day: {
    borderRadius: '0',
    color: 'inherit',
    fontSize: 14,
    height: 36,
    margin: 0,
    width: 36,
  },
  dayWrapper: {
    position: 'relative',
  },
  isSelected: {
    background: '#E1FDE8',
  },
  isSelectedEnd: {
    background: '#E1FDE8',
    borderRadius: '0 50% 50% 0',
  },
  isSelectedStart: {
    background: '#E1FDE8',
    borderRadius: '50% 0 0 50%',
  },
}))

const Calendar: React.FunctionComponent<any> = ({
  classes,
  onChange,
  range,
}) => {
  const [open, setOpen] = useState({
    context: 'init',
    endSet: false,
    isOpen: false,
    startSet: false,
  })
  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(new Date())
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)
  const message = useMessage()
  const utils = useContext(MuiPickersContext)

  useEffect(() => {
    if (range) {
      range.start && setStart(new Date(range.start))
      range.end && setEnd(new Date(range.end))
      setOpen({
        context: '',
        endSet: !!range.end,
        isOpen: false,
        startSet: !!range.start,
      })
    }
  }, [range])

  const min = Math.min(start, end)
  const max = Math.max(start, end)

  const renderDay = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
    const isEnd = utils.isSameDay(day, max)
    const isStart = utils.isSameDay(day, min)
    return (
      <div
        className={classNames({
          [classes.isSelectedEnd]: isEnd,
          [classes.isSelectedStart]: isStart,
        })}
      >
        {React.cloneElement(dayComponent, {
          className: classNames(classes.day, {
            [classes.cap]: isEnd || isStart,
            [classes.isSelected]: day >= min && day <= max,
          }),
          onClick: (e) => {
            e.stopPropagation()
            if (open.context === 'start' && utils.isSameDay(day, start)) {
              setStart(new Date())
              setOpen({ ...open, startSet: false })
              onChange({ end, start: undefined })
            } else if (
              open.context === 'start' &&
              (utils.isBefore(day, end) || utils.isEqual(day, end))
            ) {
              setStart(day)
              setOpen({ ...open, startSet: true })
              onChange({ end, start: day })
            } else if (open.context === 'start' && !open.startSet) {
              setStart(day)
              setOpen({ ...open, startSet: true })
              onChange({ end: end, start: day })
            } else if (
              open.context === 'end' &&
              utils.isSameDay(day, end) &&
              open.endSet
            ) {
              setEnd(new Date())
              setOpen({ ...open, endSet: false })
              onChange({
                end: undefined,
                start: open.startSet ? start : undefined,
              })
            } else if (
              open.context === 'end' &&
              (utils.isAfter(day, start) || utils.isSameDay(day, start))
            ) {
              setEnd(day)
              setOpen({ ...open, endSet: true })
              onChange({ end: day, start })
            } else if (open.context === 'end' && !open.endSet) {
              setEnd(day)
              setOpen({ ...open, endSet: true })
              onChange({ end: day, start })
            }
            setIsComponentVisible(false)
          },
        })}
      </div>
    )
  }

  const focused = (context) => {
    setOpen({ ...open, context })
    setIsComponentVisible(true)
  }

  return (
    <div className={style.calendarContainer} ref={ref}>
      <div className={style.calendarInputContainer}>
        <CalendarTodayOutlinedIcon className={style.calendarTodayOutlined} />
        <TextField
          InputLabelProps={{
            style: { fontSize: 16 },
          }}
          inputProps={{
            onKeyDown: (event) => event.preventDefault(),
          }}
          InputProps={{
            endAdornment: open.startSet && (
              <div
                className={classes.clearCalendar}
                onClick={() => {
                  setStart(undefined)
                  setOpen({
                    context: 'start',
                    endSet: !!end,
                    isOpen: false,
                    startSet: false,
                  })
                  onChange({ end, start: undefined })
                }}
              >
                x
              </div>
            ),
          }}
          label={message('components.Calendar.startDate')}
          onFocus={() => focused('start')}
          value={open.startSet ? utils.format(start, 'dd LLL yyyy') : ''}
          variant="outlined"
        />
        <div className={style.calendarDivider}>to</div>
        <TextField
          InputLabelProps={{
            style: { fontSize: 16 },
          }}
          inputProps={{
            onKeyDown: (event) => event.preventDefault(),
          }}
          InputProps={{
            endAdornment: open.endSet && (
              <div
                className={classes.clearCalendar}
                onClick={() => {
                  setEnd(undefined)
                  setOpen({
                    context: 'end',
                    endSet: false,
                    isOpen: false,
                    startSet: !!start,
                  })
                  onChange({ end: undefined, start })
                }}
              >
                x
              </div>
            ),
          }}
          label={message('components.Calendar.endDate')}
          onFocus={() => focused('end')}
          value={open.endSet ? utils.format(end, 'dd LLL yyyy') : ''}
          variant="outlined"
        />
      </div>
      {isComponentVisible && (
        <Paper className={style.calendar}>
          <MuiCalendar
            date={
              open.context === 'start' ? start || new Date() : end || new Date()
            }
            renderDay={renderDay}
          />
        </Paper>
      )}
    </div>
  )
}

export default withStyles(styles)(Calendar)

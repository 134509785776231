import React from 'react'
import style from './style.less'

type TimeLabelProps = {
  seconds: number
  showMs?: boolean
}

const msToTimeString = (seconds: number, showMs: boolean) => {
  const h = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0')
  const m = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const s = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0')
  let time = h + ':' + m + ':' + s
  if (showMs) {
    const ms = (seconds % 1).toFixed(2).substring(2)
    time = time + '.' + ms
  }
  return time
}

const TimeLabel: React.FunctionComponent<TimeLabelProps> = ({
  seconds,
  showMs = true,
}) => {
  return <span className={style.label}>{msToTimeString(seconds, showMs)}</span>
}

export default TimeLabel

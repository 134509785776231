import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Button from '@material-ui/core/Button'
import { useMessage } from '../../hooks'
import style from './style.less'

const AccessDenied: React.FunctionComponent = () => {
  const { logout } = useAuth0()
  const messages = useMessage()
  return (
    <div className={style.accessDenied}>
      <h1>{messages('components.AccessDenied.accessDeniedMessage')}</h1>
      <Button onClick={() => logout({ returnTo: window.location.origin })}>{messages('components.AccessDenied.backToLoginButton')}</Button>
    </div>
  )
}

export default AccessDenied

const GA_EVENT_ACTIONS = {
  INCIDENT_EDITOR: {
    ADD_INCIDENT: 'Add incident',
    CREATE_INCIDENT: 'Create incident',
    DELETE_INCIDENT: 'Delete incident',
    NEXT_INCIDENT: 'Next incident',
    PREVIOUS_INCIDENT: 'Previous incident',
  },
  SESSION_OVERVIEW: {
    DATE_CHANGE: 'Date change',
    EDIT_SESSION: 'Edit session',
    SEARCH: 'Search',
  },
}

export default GA_EVENT_ACTIONS

import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'
import { useHistory } from 'react-router-dom'
import { useMessage } from '../../hooks'
import style from './style.less'

type IncidentNavigationProps = {
  currentPage: number
  triggerViewChange: (value: string) => void
  view: string
}

const IncidentNavigation: React.FunctionComponent<IncidentNavigationProps> = ({
  currentPage,
  triggerViewChange,
  view,
}) => {
  const message = useMessage()
  const history = useHistory()

  const onExit = async () => {
    history.push('/sessions/overview')
  }

  return (
    <div className={style.incidentNavigation}>
      <ToggleButtonGroup
        className={style.incidentNavigationToggle}
        value={view}
      >
        <ToggleButton
          aria-label={message('pages.IncidentEditor.mapView')}
          color="primary"
          onClick={() => triggerViewChange('map')}
          value="map"
        >
          <RoomOutlinedIcon fontSize="small" style={{ marginRight: 0 }} />
        </ToggleButton>
        <ToggleButton
          aria-label={message('pages.IncidentEditor.videoView')}
          color="primary"
          onClick={() => triggerViewChange('video')}
          value="video"
        >
          <VideocamOutlinedIcon fontSize="small" style={{ marginRight: 0 }} />
        </ToggleButton>
      </ToggleButtonGroup>
      <div className={style.exitButton} onClick={onExit} type="submit">
        {message('components.IncidentNavigation.leaveSession')}
      </div>
    </div>
  )
}

export default IncidentNavigation

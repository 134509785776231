import useReactRouter from 'use-react-router'

type reactRouterParameters = {
  incidentId?: string
  sessionId?: string
  token?: string
  uuid?: string
}

const useReactRouterParameters = (): reactRouterParameters => {
  const { match } = useReactRouter()

  if (!match.params) {
    return {}
  }

  return match.params
}

export default useReactRouterParameters

import React, { ReactChild, ReactChildren } from 'react'
import { ApolloProvider, ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

import config from './config'

interface AuthApolloProviderProps {
  children: ReactChild | ReactChildren
}

const cache = new InMemoryCache()
const httpLink = new HttpLink({ uri: config.apolloUrl })

const AuthApolloProvider = ({ children }: AuthApolloProviderProps): any => {
  const { getAccessTokenSilently } = useAuth0()

  const authMiddleware = setContext(async (_, { headers, ...context }) => {
    const token = await getAccessTokenSilently()

    return {
      headers: {
        ...headers,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
      ...context,
    }
  })

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        // For now we just log them, if we want to handle it somehow, its here
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      )
    }
    if (networkError) {
      console.log(`[Network error]: Message: ${networkError}`)
    }
  })

  const client = new ApolloClient({
    cache,
    link: ApolloLink.from([errorLink, authMiddleware, httpLink]),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthApolloProvider

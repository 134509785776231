// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__contentColumn--aVSmJ {\n  display: flex;\n  padding: 24px;\n  width: 60%;\n}\n@media (max-width: 768px) {\n  .style__contentColumn--aVSmJ {\n    width: 100%;\n  }\n}\n.style__incidentEditor--rjhff {\n  background: #000000;\n  color: #fff;\n  display: flex;\n  height: 100%;\n}\n@media (max-width: 768px) {\n  .style__incidentEditor--rjhff {\n    flex-direction: column;\n  }\n}\n.style__mapColumn--eUb7B {\n  width: 100%;\n  height: 100%;\n}\n.style__videoColumn--FRCHt {\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/IncidentEditor/style.less"],"names":[],"mappings":"AAEA;EAEI,aAAA;EACA,aAAA;EACA,UAAA;AAFJ;AAII;EAAA;IACE,WAAA;EADJ;AACF;AAPA;EAYI,mBAAA;EACA,WAAA;EACA,aAAA;EACA,YAAA;AAFJ;AAII;EAAA;IACE,sBAAA;EADJ;AACF;AAlBA;EAuBI,WAAA;EACA,YAAA;AAFJ;AAtBA;EA4BI,WAAA;AAHJ","sourcesContent":["@import '../../style/responsive.less';\n\n:local {\n  .contentColumn {\n    display: flex;\n    padding: 24px;\n    width: 60%;\n\n    @media (max-width: @breakpoint-md) {\n      width: 100%;\n    }\n  }\n\n  .incidentEditor {\n    background: #000000;\n    color: #fff;\n    display: flex;\n    height: 100%;\n\n    @media (max-width: @breakpoint-md) {\n      flex-direction: column;\n    }\n  }\n\n  .mapColumn {\n    width: 100%;\n    height: 100%;\n  }\n\n  .videoColumn {\n    width: 100%;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentColumn": "style__contentColumn--aVSmJ",
	"incidentEditor": "style__incidentEditor--rjhff",
	"mapColumn": "style__mapColumn--eUb7B",
	"videoColumn": "style__videoColumn--FRCHt"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__calendar--LssNI {\n  margin-top: 4px;\n  z-index: 1000;\n}\n.style__calendarContainer--Dvioe {\n  display: flex;\n  flex-direction: column;\n  width: 408px;\n}\n.style__calendarDivider--oblXK {\n  color: rgba(0, 0, 0, 0.6);\n  font-size: 14px;\n  letter-spacing: 0.15px;\n  padding: 16px;\n}\n.style__calendarInputContainer--UxVcm {\n  align-items: center;\n  display: flex;\n  width: 408px;\n}\n.style__calendarTodayOutlined--Hlaxg {\n  color: #000000;\n  fill: rgba(0, 0, 0, 0.54) !important;\n  margin: 1rem;\n  height: 24px;\n  width: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Calendar/style.less"],"names":[],"mappings":"AAAA;EAEI,eAAA;EACA,aAAA;AAAJ;AAHA;EAOI,aAAA;EACA,sBAAA;EACA,YAAA;AADJ;AARA;EAaI,yBAAA;EACA,eAAA;EACA,sBAAA;EACA,aAAA;AAFJ;AAdA;EAoBI,mBAAA;EACA,aAAA;EACA,YAAA;AAHJ;AAnBA;EA0BI,cAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;AAJJ","sourcesContent":[":local {\n  .calendar {\n    margin-top: 4px;\n    z-index: 1000;\n  }\n\n  .calendarContainer {\n    display: flex;\n    flex-direction: column;\n    width: 408px;\n  }\n\n  .calendarDivider {\n    color: rgba(0, 0, 0, 0.6);\n    font-size: 14px;\n    letter-spacing: 0.15px;\n    padding: 16px;\n  }\n\n  .calendarInputContainer {\n    align-items: center;\n    display: flex;\n    width: 408px;\n  }\n\n  .calendarTodayOutlined {\n    color: #000000;\n    fill: rgba(0, 0, 0, 0.54) !important;\n    margin: 1rem;\n    height: 24px;\n    width: 24px;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"calendar": "style__calendar--LssNI",
	"calendarContainer": "style__calendarContainer--Dvioe",
	"calendarDivider": "style__calendarDivider--oblXK",
	"calendarInputContainer": "style__calendarInputContainer--UxVcm",
	"calendarTodayOutlined": "style__calendarTodayOutlined--Hlaxg"
};
export default ___CSS_LOADER_EXPORT___;

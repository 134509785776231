import React, { useState, useEffect, ReactElement } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_TELEMETRIES } from '../../services/apollo/queries'
import Telemetry from '../Telemetry/Telemetry'

const TURN_SIGNAL_OFF = 'off'
const SEGMENT_LENGTH = 30

interface TelemetryOverlayProps {
  onLoading: (boolean) => void
  offset: number
  duration: number
  sessionId: string
}

const defaultTelemtry = {
  acceleration: 0.0,
  brakePressed: false,
  gasPedal: 0.0,
  gear: undefined,
  speed: 0.0,
  steeringWheelAngle: 0.0,
  turnSignals: TURN_SIGNAL_OFF,
}

const TelemetryOverlay = ({
  onLoading,
  offset,
  sessionId,
  duration,
}: TelemetryOverlayProps): ReactElement => {
  const [telemetries, setTelemetries] = useState([])
  const [pendingRequest, setPendingRequest] = useState(false)
  const [loading, setLoading] = useState(true)
  const [
    getTelemetries,
    { data: telemetryData },
  ] = useLazyQuery(GET_TELEMETRIES, { fetchPolicy: 'cache-and-network' })
  const [telemetry, setTelemetry] = useState(defaultTelemtry)
  const [telemetryOffset, setTelemetryOffset] = useState()

  useEffect(() => {
    const initialTelemetry = []
    const numberOfSegments = Math.ceil(duration / SEGMENT_LENGTH)
    for (let i = 0; i < numberOfSegments; i++) {
      initialTelemetry.push([])
    }
    setTelemetries(initialTelemetry)
  }, [])

  useEffect(() => {
    if (telemetryData) {
      const newTelemetries = [...telemetries]
      const startSegment = Math.floor(
        telemetryOffset.startOffset / SEGMENT_LENGTH
      )
      const endSegment = Math.floor(telemetryOffset.endOffset / SEGMENT_LENGTH)

      for (let i = startSegment; i < endSegment + 1; i++) {
        if (!newTelemetries[i]) {
          newTelemetries[i] = getDefaultSubSegments()
        }
      }

      for (
        let currentOffset = telemetryOffset.startOffset;
        currentOffset <= telemetryOffset.endOffset;
        currentOffset = currentOffset + 0.1
      ) {
        newTelemetries[Math.floor(currentOffset / SEGMENT_LENGTH)][
          Math.floor(currentOffset * 10) % 300
        ] = defaultTelemtry
      }

      telemetryData.telemetries.data.forEach((telemetry, index) => {
        const currentOffset = telemetryOffset.startOffset + index * 0.1
        newTelemetries[Math.floor(currentOffset / SEGMENT_LENGTH)][
          Math.floor(currentOffset * 10) % 300
        ] = telemetry
      })

      setTelemetries(newTelemetries)
      setPendingRequest(false)
    }
  }, [telemetryData])

  useEffect(() => {
    onLoading(loading)
  }, [loading])

  useEffect(() => {
    if (telemetries.length) {
      const currentTelemetry =
        telemetries[Math.floor(offset / SEGMENT_LENGTH)][
          Math.floor(offset * 10) % 300
        ]
      if (!currentTelemetry) {
        setLoading(true)
        const endOffset = offset + 5 < duration ? offset + 5 : duration
        setTelemetryOffset({
          endOffset: endOffset,
          startOffset: offset,
        })
      } else {
        setTelemetry(currentTelemetry)
        const futurePosition = offset + 5 < duration ? offset + 5 : duration
        const futurTelemetry =
          telemetries[Math.floor(futurePosition / SEGMENT_LENGTH)][
            Math.floor(futurePosition * 10) % 300
          ]
        if (!futurTelemetry && !pendingRequest) {
          setTelemetryOffset({
            endOffset: telemetryOffset.endOffset + 5,
            startOffset: telemetryOffset.endOffset,
          })
        }
      }
    }
  }, [offset, telemetries])

  useEffect(() => {
    if (telemetryOffset) {
      getTelemetries({
        variables: { offset: telemetryOffset, sessionId },
      })
      setPendingRequest(true)
    }
  }, [telemetryOffset])

  useEffect(() => {
    if (loading && telemetries.length) {
      telemetries[Math.floor(offset / SEGMENT_LENGTH)][
        Math.floor(offset * 10) % 300
      ] && setLoading(false)
    }
  }, [telemetries, loading])

  return <Telemetry telemetry={telemetry} />
}

const getDefaultSubSegments = () => {
  const subSegment = []
  for (let i = 0; i < SEGMENT_LENGTH * 10; i++) {
    subSegment.push()
  }
  return subSegment
}

export default TelemetryOverlay

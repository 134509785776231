import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import IncidentColumn from '../../components/IncidentColumn'
import VideoPane from '../../components/VideoPane'
import Map from '../../components/Map'
import { Incident } from '../../../types'
import {
  CREATE_INCIDENT,
  DELETE_INCIDENT,
} from '../../services/apollo/mutations'
import DarkTheme from '../../components/DarkTheme'
import GA_EVENT_ACTIONS from '../../tracking/consts/eventActions'
import GA_EVENT_CATEGORIES from '../../tracking/consts/eventCategories'
import trackEvent from '../../tracking/util/trackEvent'
import style from './style.less'

type IncidentEditorProps = {
  incident: Incident
  incidents: Incident[]
  sessionId: string
  sessionDuration: number
  geoFeatureCollection: Record<string, any>
  clearCache: () => void
}

type ChangedValues = {
  changed: boolean
  comment?: string
  endOffset?: number
  isSafe?: boolean
  outcomeIds?: string[]
  startOffset?: number
  triggerId?: string
}

const defaultValues = {
  changed: false,
  comment: undefined,
  endOffset: undefined,
  isSafe: undefined,
  outcomeIds: undefined,
  startOffset: undefined,
  triggerId: undefined,
}

export const IncidentEditor: React.FunctionComponent<IncidentEditorProps> = ({
  incident,
  incidents,
  sessionId,
  sessionDuration,
  geoFeatureCollection,
  clearCache,
}) => {
  const [changedValues, setChangedValues] = useState<ChangedValues>(
    defaultValues
  )
  const [currentView, setCurrentView] = useState<string>('video')
  const [pendingNewIncident, setPendingNewIncident] = useState<string>(null)
  const [pendingDeletedIncident, setPendingDeletedIncident] = useState<string>(
    null
  )

  const [creatIncident] = useMutation(CREATE_INCIDENT, {
    onCompleted: (rest) => {
      console.log('onCompleted', rest)
    },
  })
  const [deleteIncident] = useMutation(DELETE_INCIDENT, {
    onCompleted: (rest) => {
      console.log('onCompleted', rest)
    },
  })

  const history = useHistory()

  useEffect(() => {
    setChangedValues(defaultValues)
    const newIncident =
      pendingNewIncident &&
      incidents.find((incident) => incident.id === pendingNewIncident)
    if (newIncident) {
      history.push(`/sessions/${sessionId}/incidents/${newIncident.id}`)
      setPendingNewIncident(null)
    }
  }, [incident, incidents, pendingNewIncident])

  useEffect(() => {
    const isDeleted =
      pendingDeletedIncident &&
      !incidents.find((incident) => incident.id === pendingDeletedIncident)
    if (isDeleted) {
      history.push(`/sessions/${sessionId}/incidents/${pendingDeletedIncident}`)
      setPendingDeletedIncident(null)
    }
  }, [incident, incidents, pendingNewIncident])

  const incidentIds = incidents.map((incident) => incident.id)
  const incidentIndex = incidentIds.indexOf(incident.id)

  const create = async () => {
    const newIncident = await creatIncident({
      variables: {
        incident: {
          endOffset: changedValues.endOffset || incident.endOffset,
          sessionId: sessionId,
          startOffset: changedValues.startOffset || incident.startOffset,
        },
      },
    }).then((resp) => {
      trackEvent({
        action: GA_EVENT_ACTIONS.INCIDENT_EDITOR.CREATE_INCIDENT,
        category: GA_EVENT_CATEGORIES.INCIDENT_EDITOR,
        label: resp.data.createIncident.id,
      })
      return resp.data.createIncident
    })
    clearCache()
    setPendingNewIncident(newIncident.id)
    return newIncident
  }

  const remove = async () => {
    trackEvent({
      action: GA_EVENT_ACTIONS.INCIDENT_EDITOR.DELETE_INCIDENT,
      category: GA_EVENT_CATEGORIES.INCIDENT_EDITOR,
      label: incident.id,
    })

    await deleteIncident({ variables: { id: incident.id } })
    clearCache()

    const nextIndex = (incidentIndex + 1) % incidents.length
    setPendingDeletedIncident(incidentIds[nextIndex])
  }

  const handleViewChange = (view) => {
    if (view !== currentView) {
      setCurrentView(view)
    }
  }

  const handleChange = (payload) => {
    setChangedValues({
      ...changedValues,
      ...payload,
      ...{ changed: true },
    })
  }

  const jumpToIncident = async (incidentId) => {
    history.push(`/sessions/${sessionId}/incidents/${incidentId}`)
  }

  return (
    <DarkTheme>
      <div className={style.incidentEditor}>
        <div className={style.contentColumn}>
          {currentView === 'video' ? (
            <div className={style.videoColumn}>
              <VideoPane
                endOffset={incident.endOffset}
                incidentIndex={incidentIndex}
                incidents={incidents}
                jumpToIncident={jumpToIncident}
                onChange={handleChange}
                sessionDuration={sessionDuration}
                sessionId={sessionId}
                // we can't set it initially to 0, because of an race condition issue on react-player side
                startOffset={
                  incident.startOffset <= 0 ? 0.0001 : incident.startOffset
                }
              />
            </div>
          ) : (
            <div className={style.mapColumn}>
              <Map
                currentIncidentId={incident.id}
                geoFeatureCollection={geoFeatureCollection}
                onIncidentSelected={jumpToIncident}
                telemetries={[]}
              />
            </div>
          )}
        </div>
        <IncidentColumn
          create={create}
          incident={incident}
          incidentIndex={incidentIndex}
          incidents={incidents}
          onChange={handleChange}
          remove={remove}
          setView={handleViewChange}
          view={currentView}
        />
      </div>
    </DarkTheme>
  )
}

export default IncidentEditor

import React, { ReactElement } from 'react'

const Rain = (): ReactElement => (
  <div>
    <svg
      fill="none"
      height="22"
      viewBox="0 0 23 22"
      width="23"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M9.64153 0.0133008C6.04743 -0.189085 2.735 1.95593 1.44932 5.3183C0.163638 8.68067 1.19985 12.4885 4.01206 14.7357C4.44351 15.0805 5.07277 15.0102 5.41754 14.5788C5.76231 14.1473 5.69204 13.5181 5.26059 13.1733C3.12825 11.4693 2.34255 8.5821 3.31741 6.03261C4.29227 3.48312 6.80389 1.85668 9.52908 2.01014C12.2543 2.16359 14.5674 4.06172 15.2499 6.70454C15.3639 7.14604 15.7622 7.4545 16.2181 7.4545H17.3636L17.5559 7.4599C19.1493 7.5477 20.4993 8.69666 20.8289 10.2754C21.1716 11.9173 20.317 13.5747 18.7806 14.2476C18.2747 14.4692 18.0442 15.0589 18.2658 15.5648C18.4873 16.0707 19.0771 16.3012 19.583 16.0796C21.986 15.0271 23.3228 12.4347 22.7867 9.86667L22.7332 9.63524C22.1131 7.18871 19.9087 5.45749 17.3648 5.4545L16.954 5.45395L16.8478 5.17558C15.6619 2.23486 12.8711 0.195159 9.64153 0.0133008ZM9.26596 11.7924C9.2082 11.2951 8.78552 10.909 8.27269 10.909C7.7204 10.909 7.27269 11.3568 7.27269 11.909V19.1818L7.27942 19.2984C7.33718 19.7957 7.75985 20.1818 8.27269 20.1818C8.82497 20.1818 9.27269 19.7341 9.27269 19.1818V11.909L9.26596 11.7924ZM16.5387 11.7924C16.4809 11.2951 16.0583 10.909 15.5454 10.909C14.9931 10.909 14.5454 11.3568 14.5454 11.909V19.1818L14.5521 19.2984C14.6099 19.7957 15.0326 20.1818 15.5454 20.1818C16.0977 20.1818 16.5454 19.7341 16.5454 19.1818V11.909L16.5387 11.7924ZM11.9091 12.7272C12.4219 12.7272 12.8446 13.1133 12.9023 13.6106L12.9091 13.7272V21C12.9091 21.5522 12.4613 22 11.9091 22C11.3962 22 10.9735 21.6139 10.9158 21.1166L10.9091 21V13.7272C10.9091 13.1749 11.3568 12.7272 11.9091 12.7272Z"
        fill="black"
        fillOpacity="0.87"
        fillRule="evenodd"
      />
    </svg>
  </div>
)

export default Rain

const config = {
  apiUrl: process.env.REACT_APP_YAAK_API_URL || '{{ REACT_APP_YAAK_API_URL }}',
  apolloUrl: process.env.REACT_APP_APOLLO_URL || '{{ REACT_APP_APOLLO_URL }}',
  auth: {
    audience:
      process.env.REACT_APP_AUTH_AUDIENCE || '{{ REACT_APP_AUTH_AUDIENCE }}',
    clientId:
      process.env.REACT_APP_AUTH_CLIENT_ID || '{{ REACT_APP_AUTH_CLIENT_ID }}',
    domain: process.env.REACT_APP_AUTH_DOMAIN || '{{ REACT_APP_AUTH_DOMAIN }}',
    scope: process.env.REACT_APP_AUTH_SCOPE || '{{ REACT_APP_AUTH_SCOPE }}',
  },
  locales: {
    default: 'en',
    supported: ['en', 'de'],
    url: '/locales',
  },
}

export default config

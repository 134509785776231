import React, { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { Incident, Incidents } from '../../../types'
import IncidentCreate from '../IncidentCreate'
import IncidentPane from '../IncidentPane'
import IncidentNavigation from '../IncidentNavigation'
import IncidentHeader from '../IncidentHeader'
import { useMessage } from '../../hooks'
import style from './style.less'

const useStyles = makeStyles({
  label: {
    fontSize: 14,
    justifyContent: 'flex-start',
    lineHeight: 20,
    textTransform: 'none',
  },
  root: {
    height: 40,
    padding: '6px 16px',
  },
})

interface IncidentColumnProps {
  incident: Incident
  incidentIndex: number
  incidents: Incidents
  view: string
  setView: (string) => void
  onChange: (any) => void
  create: () => void
  remove: () => void
}

const IncidentColumn = ({
  incident,
  incidentIndex,
  incidents,
  view,
  setView,
  onChange,
  create,
  remove,
}: IncidentColumnProps): ReactElement => {
  const classes = useStyles()
  const [creating, setCreating] = useState(incident.id === 'new')
  const [deleting, setDeleting] = useState(false)
  const [activeMenu, setActiveMenu] = useState(false)

  const history = useHistory()
  const message = useMessage()

  const change = (payload) => {
    onChange(payload)
  }

  const handleCreateCancel = () => {
    incidents.length ? setCreating(false) : history.push('/sessions/overview')
  }

  const incidentIds = incidents.map(({ id }) => id)

  return (
    <div className={style.incidentColumn}>
      {creating ? (
        <IncidentCreate onCancel={handleCreateCancel} onCreate={create} />
      ) : (
        <>
          {activeMenu && (
            <div
              className={style.menuOverlay}
              onClick={() => setActiveMenu(false)}
            >
              <div className={style.menu}>
                <Button
                  classes={{
                    label: classes.label,
                    root: classes.root,
                  }}
                  fullWidth
                  onClick={() => {
                    setCreating(true)
                  }}
                >
                  {message('components.IncidentEditMenu.addNewEvent')}
                </Button>
                <Button
                  classes={{
                    label: classes.label,
                    root: classes.root,
                  }}
                  fullWidth
                  onClick={() => {
                    setDeleting(true)
                  }}
                  style={{ color: '#D33130' }}
                >
                  {message('components.IncidentEditMenu.deleteEvent')}
                </Button>
              </div>
            </div>
          )}
          <div className={style.incidentNav}>
            <IncidentHeader
              currentPage={incidentIndex + 1}
              incidentIds={incidentIds}
              pageCount={incidents.length}
              sessionName={incident.sessionName}
              triggerCreate={() => {
                setView('video')
                setActiveMenu(true)
              }}
            />
            <IncidentPane incidentId={incident.id} onChange={change} />

            <IncidentNavigation
              currentPage={incidentIndex + 1}
              triggerViewChange={setView}
              view={view}
            />
          </div>
        </>
      )}
      <Dialog
        aria-labelledby="form-dialog-title"
        onClose={() => setDeleting(false)}
        open={deleting}
      >
        <div className={style.deleteEventDialog}>
          <DialogTitle id="form-dialog-title">
            {`${message('components.IncidentDelete.deleteEventTitle')} ${
              incidentIndex + 1
            }`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {message('components.IncidentDelete.deleteEventDescription')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleting(false)}>
              {message('components.IncidentDelete.cancel')}
            </Button>
            <Button onClick={() => remove()}>
              {message('components.IncidentDelete.deleteEvent')}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}

export default IncidentColumn

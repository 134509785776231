import classNames from 'classnames'
import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { CircleFlag } from 'react-circle-flags'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { LanguageContext } from '../../context'
import { useMessage, useComponentVisible } from '../../hooks'
import { HelpIcon, LogoutIcon, SettingsIcon } from '../../assets'
import style from './style.less'

const FlagComponent = (name, countryCode) => (
  <div className={style.flag}>
    <CircleFlag countryCode={countryCode} height="24" />
    <div className={style.textContainer}>{name}</div>
  </div>
)

const LogoutComponent = () => (
  <div className={style.logout}>
    <LogoutIcon />
    <div className={style.textContainer}>Log Out</div>
  </div>
)

const getGreeting = (username, message) => {
  const currentHour = new Date().getHours()
  if (currentHour >= 5 && currentHour < 12) {
    return message('pages.SessionOverview.title.morning', { username })
  } else if (currentHour >= 12 && currentHour < 17) {
    return message('pages.SessionOverview.title.afternoon', { username })
  } else {
    return message('pages.SessionOverview.title.evening', { username })
  }
}

const LanguageSwitch = (): ReactElement => {
  const { user, logout } = useAuth0()
  const history = useHistory()

  const [username, setUsername] = useState('')
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false)

  const message = useMessage()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    setUsername(user.given_name)
  }, [user])

  const { language, updateLanguage } = useContext(LanguageContext)

  const update = (lang) => {
    updateLanguage(lang)
  }

  return (
    <div className={style.languageSwitch}>
      <div className={style.greeting}>{getGreeting(username, message)}</div>
      <div
        className={style.userAvatar}
        onClick={() => setIsComponentVisible(true)}
      >
        {user.name[0].toUpperCase()}
      </div>
      {isComponentVisible && (
        <div
          className={style.menu}
          onClick={() => setIsComponentVisible(false)}
          ref={ref}
        >
          <div
            className={classNames(
              style.menuItem,
              language === 'de' && style.menuItemSelected
            )}
            onClick={() => update('de')}
          >
            {FlagComponent('Deutsch', 'de')}
          </div>
          <div
            className={classNames(
              style.menuItem,
              language === 'en' && style.menuItemSelected
            )}
            onClick={() => update('en')}
          >
            {FlagComponent('English', 'gb')}
          </div>
          <div className={style.delimiter} />
          <div
            className={style.menuItem}
            onClick={() => history.push('/sessions/settings')}
          >
            <div className={style.help}>
              <SettingsIcon style={{ margin: '16px' }} />
              {message('components.Menu.settings')}
            </div>
          </div>
          <div
            className={style.menuItem}
            onClick={() => {
              window.open('https://support.yaak.ai/')
            }}
          >
            <div className={style.help}>
              <HelpIcon style={{ margin: '20px' }} />
              {message('components.Menu.help')}
            </div>
          </div>
          <div className={style.delimiter} />
          <div
            className={style.menuItem}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {LogoutComponent()}
          </div>
        </div>
      )}
    </div>
  )
}

export default LanguageSwitch

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Auth0Provider } from '@auth0/auth0-react'
import AuthApolloProvider from './AuthApolloProvider'
import Preloader from './components/Preloader'
import LightTheme from './components/LightTheme'
import Routes from './Routes'
import Login from './pages/Login'
import { LanguageProvider } from './context'
import config from './config'
import AccessDenied from './components/AccessDenied'

const Auth0App : React.FunctionComponent = () => {
  const accessDeniedError = document.location.search.includes('error=access_denied')

  return (
    <Auth0Provider
      audience={config.auth.audience}
      clientId={config.auth.clientId}
      domain={config.auth.domain}
      redirectUri={`${window.location.origin}?location=${window.location.pathname}`}
      scope={config.auth.scope}
      useRefreshTokens={true}
    >
      <LanguageProvider>
        <Preloader>
          { accessDeniedError
            ? <AccessDenied/>
            : <LightTheme>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <AuthApolloProvider>
                  <CssBaseline />
                  <Router>
                    <Switch>
                      <Route path="/auth">
                        <Login />
                      </Route>
                      <Route path="/">
                        <Routes />
                      </Route>
                    </Switch>
                  </Router>
                </AuthApolloProvider>
              </MuiPickersUtilsProvider>
            </LightTheme>}
        </Preloader>
      </LanguageProvider>
    </Auth0Provider>
  )
}

ReactDOM.render(
  <Auth0App/>,
  document.getElementById('root')
)

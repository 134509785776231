import React, { useEffect, useState, useRef } from 'react'
import classNames from 'classnames'
import { Rnd } from 'react-rnd'
import TimeLabel from '../TimeLabel'
import style from './style.less'

// How many +/- seconds to allow
const SCRUBBER_PADDED_SECONDS = 5
// Width of a second on the timeline. Correlates with styles!!!
const SECOND_WIDTH = 30
const MAX_OFFSET = 60
const LEFT_MARGIN = 28
const SCROLLING_SPEED = 40
const LEFT_HANDLE = 'left'

type ScrubberProps = {
  endOffset: number
  startOffset: number
  duration: number
  currentOffset: number
}

const Scrubber: React.FunctionComponent<ScrubberProps> = ({
  endOffset,
  startOffset,
  duration,
  currentOffset,
}) => {
  const [positionX, setPositionX] = useState(
    SCRUBBER_PADDED_SECONDS * SECOND_WIDTH
  )
  const width = (endOffset - startOffset) * SECOND_WIDTH
  const [scrubbedStart, setScrubbedStart] = useState(startOffset)
  const [scrubbedEnd, setScrubbedEnd] = useState(endOffset)
  const [scrubberHeight, setScrubberHeight] = useState(48)
  const [timeBox, setTimeBox] = useState({ end: duration, start: 0 })
  const [timeLabels, setTimeLabels] = useState([])
  const [scrollPostion, setScrollPosition] = useState(null)
  const [knopPosition, setKnopPosition] = useState(0)
  const [scrubbing] = useState(false)

  const [scrollInterval, setScrollInterval] = useState(null)
  const [scrolling] = useState({
    direction: 0,
    handle: '',
    sizeIncreasing: false,
  })

  const timeLine = useRef(null)
  const scrubber = useRef(null)
  const [scrub, setScrub] = useState(null)

  useEffect(() => {
    if (scrolling.direction && !scrollInterval) {
      const factor = scrolling.direction * SECOND_WIDTH
      let currentWidth = width
      setScrollInterval(
        setInterval(() => {
          const currentScroll = timeLine.current.scrollLeft
          timeLine.current.scrollLeft = currentScroll + factor
          setScrollPosition(currentScroll + factor)

          if (scrolling.handle === LEFT_HANDLE) {
            currentWidth = currentWidth - factor
            scrub.updateSize({
              height: scrubberHeight,
              width: currentWidth,
            })
            scrub.updatePosition({
              x:
                scrolling.direction === -1
                  ? currentScroll
                  : currentScroll + timeLine.current.offsetWidth,
              y: 0,
            })
          } else {
            currentWidth = currentWidth + factor
            scrub.updateSize({
              height: scrubberHeight,
              width: currentWidth,
            })
          }
        }, SCROLLING_SPEED)
      )
    } else if (!scrolling.direction && scrollInterval) {
      clearInterval(scrollInterval)
      setScrollInterval(null)
    }
  }, [scrolling, scrollInterval])

  useEffect(() => {
    if (scrollPostion !== null && timeLine) {
      const newPosition =
        currentOffset < scrubbedStart || currentOffset > scrubbedEnd
          ? (scrubbedStart - timeBox.start) * SECOND_WIDTH
          : (currentOffset - timeBox.start) * SECOND_WIDTH
      if (
        newPosition >
          scrollPostion + timeLine.current.offsetWidth - SECOND_WIDTH &&
        !scrubbing
      ) {
        const newScroll =
          scrollPostion +
          newPosition -
          (scrollPostion + timeLine.current.offsetWidth) +
          SECOND_WIDTH +
          LEFT_MARGIN
        timeLine.current.scrollLeft = newScroll
        setScrollPosition(newScroll)
      } else if (newPosition < timeLine.current.scrollLeft && !scrubbing) {
        timeLine.current.scrollLeft = newPosition
        setScrollPosition(newPosition)
      }
      setKnopPosition(newPosition)
    }
  }, [
    currentOffset,
    timeBox,
    scrollPostion,
    timeLine,
    startOffset,
    endOffset,
    scrubbing,
  ])

  useEffect(() => {
    if (timeLine) {
      const timeboxSize =
        (timeBox.end - timeBox.start) * SECOND_WIDTH + LEFT_MARGIN
      if (
        scrollPostion < 10 * SECOND_WIDTH &&
        timeLabels &&
        timeBox.start > 0
      ) {
        const newStart =
          timeBox.start - MAX_OFFSET < 0 ? 0 : timeBox.start - MAX_OFFSET
        const labels = []
        for (let i = newStart; i < timeBox.start; i = i + 5) {
          labels.push(i)
        }
        const diff = timeBox.start - newStart
        setTimeLabels(labels.concat(timeLabels))
        setTimeBox({ end: timeBox.end, start: newStart })
        setPositionX(positionX + diff * SECOND_WIDTH - LEFT_MARGIN)
        setScrollPosition(timeLine.current.scrollLeft + diff * SECOND_WIDTH)
        timeLine.current.scrollLeft =
          timeLine.current.scrollLeft + diff * SECOND_WIDTH
      } else if (
        scrollPostion + timeLine.current.offsetWidth >
          timeboxSize - 10 * SECOND_WIDTH &&
        timeBox.end < duration
      ) {
        const newEnd =
          timeBox.end + MAX_OFFSET > duration
            ? duration
            : timeBox.end + MAX_OFFSET
        const roundEnd = Math.floor(newEnd) - (Math.floor(newEnd) % 5)
        const labels = []
        for (let i = roundEnd; i > timeBox.end; i = i - 5) {
          labels.push(i)
        }
        setTimeLabels(timeLabels.concat(labels.reverse()))
        setTimeBox({ end: newEnd, start: timeBox.start })
      }
    }
  }, [scrollPostion, timeLine])

  useEffect(() => {
    if (timeLine) {
      const viewSize = timeLine.current.offsetWidth
      const scrubberSize = (scrubbedEnd - scrubbedStart) * SECOND_WIDTH
      const start =
        startOffset - MAX_OFFSET < 0
          ? 0
          : Math.floor(startOffset - MAX_OFFSET) - (Math.floor(startOffset) % 5)
      const end =
        endOffset + MAX_OFFSET > duration ? duration : endOffset + MAX_OFFSET

      const posX = (startOffset - start) * SECOND_WIDTH
      let scrollX = posX - (viewSize - scrubberSize) / 2
      if (scrubberSize > viewSize) {
        scrollX = posX - SECOND_WIDTH
      }

      const labels = []
      for (let i = start; i < end; i = i + 5) {
        labels.push(i)
      }

      setTimeLabels(labels)
      setTimeBox({ end, start })
      setPositionX(posX)
      setScrollPosition(scrollX - LEFT_MARGIN)

      timeLine.current.scrollLeft = scrollX
    }
  }, [timeLine, startOffset, endOffset])

  useEffect(() => {
    setScrubberHeight(scrubber.current.clientHeight)
  }, [scrubber])

  // WTF?
  useEffect(() => {
    setScrubbedStart(startOffset)
    setScrubbedEnd(endOffset)
  }, [startOffset, endOffset])

  return (
    <div className={style.scrubber} ref={timeLine}>
      <div
        className={style.timeLineBox}
        style={{
          width: (timeBox.end - timeBox.start) * SECOND_WIDTH + 28,
        }}
      >
        <ul className={style.labelList}>
          {timeLabels.map((time) => (
            <li className={style.labelContainer} key={time}>
              <TimeLabel seconds={time} showMs={false} />
            </li>
          ))}
        </ul>
        <div className={style.scrubbingBox} ref={scrubber}>
          <div className={style.timeLineElement} />
          <div
            className={style.playHead}
            style={{
              left: `${knopPosition}px`,
            }}
          >
            <div className={style.head} />
          </div>
          <Rnd
            className={style.bar}
            disableDragging={true}
            enableResizing={{
              bottom: false,
              bottomLeft: false,
              bottomRight: false,
              left: false,
              right: false,
              top: false,
              topLeft: false,
              topRight: false,
            }}
            position={{
              x: positionX,
              y: 0,
            }}
            ref={(c) => setScrub(c)}
            resizeHandleComponent={{
              left: (
                <div
                  className={style.leftHandle}
                  style={{
                    backgroundColor: scrubbing ? '#00EE77' : '#9E9E9E',
                    backgroundImage: `radial-gradient(circle, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px, rgba(255,255,255,0) 1px), linear-gradient(to bottom, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 0, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px),radial-gradient(circle, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px, rgba(255,255,255,0) 1px)`,
                  }}
                />
              ),
              right: (
                <div
                  className={classNames(style.leftHandle, style.rightHandle)}
                  style={{
                    backgroundColor: scrubbing ? '#00EE77' : '#9E9E9E',
                    backgroundImage: `radial-gradient(circle, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px, rgba(255,255,255,0) 1px), linear-gradient(to bottom, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 0, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px),radial-gradient(circle, ${
                      scrubbing ? '#016A35' : '#565656'
                    } 1px, rgba(255,255,255,0) 1px)`,
                  }}
                />
              ),
            }}
            resizeHandleStyles={{
              left: { left: 0 },
              right: { right: 0 },
            }}
            size={{
              height: scrubberHeight,
              width,
            }}
            style={{
              background: scrubbing
                ? 'rgba(0, 238, 119, 0.12)'
                : 'rgba(255, 255, 255, 0.12)',
              border: `1px solid ${
                scrubbing ? 'rgba(0, 238, 119, 0.6)' : '#9E9E9E'
              }`,
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default Scrubber

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style__playbackBar--xJoSo {\n  height: 2rem;\n  margin-top: 4px;\n  max-height: 2.96vh;\n  min-height: 2.96vh;\n  width: 100%;\n}\n.style__scrubber--Up1g7 {\n  height: 3rem;\n  width: 100%;\n}\n.style__videoControls--dZesb {\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-end;\n  min-width: 0;\n  width: 100%;\n}\n.style__video--jGLmT {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/VideoPane/style.less"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,WAAA;AAAJ;AANA;EAUI,YAAA;EACA,WAAA;AADJ;AAVA;EAeI,aAAA;EACA,sBAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;AAFJ;AAjBA;EAuBI,aAAA;EACA,sBAAA;EACA,YAAA;AAHJ","sourcesContent":[":local {\n  .playbackBar {\n    height: 2rem;\n    margin-top: 4px;\n    max-height: 2.96vh;\n    min-height: 2.96vh;\n    width: 100%;\n  }\n\n  .scrubber {\n    height: 3rem;\n    width: 100%;\n  }\n\n  .videoControls {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    min-width: 0;\n    width: 100%;\n  }\n\n  .video {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n  }\n}\n\n@font-family: Basis Grotesque, Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif;@primary-color: #5097AB;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"playbackBar": "style__playbackBar--xJoSo",
	"scrubber": "style__scrubber--Up1g7",
	"videoControls": "style__videoControls--dZesb",
	"video": "style__video--jGLmT"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

import { UserIcon, EmailIcon } from '../../../../assets'
import { useMessage } from '../../../../hooks'
import style from './style.less'

type ModalProps = {
  open: boolean
  title: string
  description: string
  onSave: () => Promise<void>
  onCancel: () => void
}

const Modal: React.FunctionComponent<ModalProps> = ({
  open,
  onSave,
  onCancel,
  title,
  description,
}) => {
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    email: false,
    firstName: false,
    lastName: false,
  })
  const message = useMessage()

  const onFirstName = (event) => setFirstName(event.target.value)

  const onLastName = (event) => setLastName(event.target.value)

  const onEmail = (event) => setEmail(event.target.value)

  const save = () => {
    const user = {
      email,
      firstName,
      lastName,
    }

    const errorState = Object.keys(user).reduce((acc, key) => {
      const isErrorState = !exists(user[key])
      acc[key] = isErrorState
      if (isErrorState) {
        acc.description = message('pages.Settings.form.mssingField')
      }
      return acc
    }, {})

    setError(errorState)

    if (!Object.values(errorState).includes(true)) {
      setLoading(true)
      onSave({
        email,
        firstName,
        lastName,
      })
        .then(onCancel)
        .catch(() => {
          setError({
            description: message('pages.Settings.form.invalid'),
            email: true,
            firstName: true,
            lastName: true,
          })
          setLoading(false)
        })
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div>{description}</div>
        <div className={style.formRow}>
          <div className={style.icon}>
            <UserIcon />
          </div>
          <TextField
            error={error.firstName}
            label={message('pages.Settings.form.firstName')}
            onChange={onFirstName}
            style={{ width: 232 }}
            value={firstName}
            variant="filled"
          />
          <TextField
            error={error.lastName}
            label={message('pages.Settings.form.lastName')}
            onChange={onLastName}
            style={{ width: 232 }}
            value={lastName}
            variant="filled"
          />
        </div>
        <div className={style.formRow}>
          <div className={style.icon}>
            <EmailIcon />
          </div>
          <TextField
            error={error.email}
            label={message('pages.Settings.form.email')}
            onChange={onEmail}
            style={{ width: 476 }}
            value={email}
            variant="filled"
          />
        </div>
        <div className={style.formRow} style={{ color: '#CA0807' }}>
          {error.description || ''}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onCancel}
          style={{
            color: '#00EE77',
            fontSize: 14,
            letterSpacing: 1.25,
          }}
        >
          CANCEL
        </Button>
        <Button
          onClick={save}
          style={{
            color: '#00EE77',
            fontSize: 14,
            letterSpacing: 1.25,
          }}
        >
          SAVE
        </Button>
      </DialogActions>
      {loading && (
        <div style={{ left: '50%', position: 'absolute', top: '50%' }}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  )
}

const exists = (string) => string && string !== ''

export default Modal
